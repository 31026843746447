@charset "UTF-8";
/*!
 * Title: Main style
 * Description: The main style
 * Author: Công ty TNHH TM-DV Thế Giới Web - thegioiwebs.net
 * Version: 1.0
 */
/**
 * The first types of settings
 */
@font-face {
  font-family: "gotham-bold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/gotham/gotham-bold.ttf") format("truetype"); }

@font-face {
  font-family: "gotham-regular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/gotham/gotham-regular.ttf") format("truetype"); }

@font-face {
  font-family: "gotham-italic";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/gotham/gotham-italic.ttf") format("truetype"); }

@font-face {
  font-family: "gotham-light";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/gotham/gotham-light.ttf") format("truetype"); }

@font-face {
  font-family: "gotham-thin";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/gotham/gotham-thin.ttf") format("truetype"); }

.fr {
  font-family: "gotham-regular"; }

.fb {
  font-family: "gotham-bold"; }

.fi {
  font-family: "gotham-italic"; }

.fl {
  font-family: "gotham-light"; }

.cl_main {
  color: #1774ff; }

.cl_white {
  color: #fff; }

.cl_red {
  color: #cb1b22; }

.cl_brown {
  color: #9b9b9b; }

.cl_blue-light {
  color: #6bc3f0; }

.cl_gray {
  color: #e6e6e6; }

.cl_orange {
  color: #f48a00; }

.bg_main {
  background-color: #1774ff; }

.bg_red {
  background-color: #cb1b22; }

.bg_gray {
  background-color: #e6e6e6; }

button {
  cursor: pointer;
  transition: 250ms; }
  button:focus {
    outline: none; }

a {
  transition: all 250ms;
  cursor: pointer;
  color: inherit; }
  a:hover, a:focus {
    text-decoration: none;
    color: inherit; }

* {
  outline: none; }

img {
  max-width: 100%;
  max-height: 100%; }

textarea {
  resize: none; }

.reset-list, .dots-style-1 .slick-dots {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.reset-btn {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer; }

.form-control:focus {
  box-shadow: none;
    box-shadow-border-color: unset; }

/**
 * Additional types of settings
 */
.bt-1 {
  border-top: 1px solid #ebebeb; }

.bb-1 {
  border-bottom: 1px solid #ebebeb; }

.br-1 {
  border-right: 1px solid #ebebeb; }

.bl-1 {
  border-left: 1px solid #ebebeb; }

.breadcrumb {
  margin-bottom: 0;
  background-color: #fff;
  padding: 15px 0; }
  .breadcrumb {
    padding-top: 0.9375rem;
    padding-bottom: 0.625rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #dedede; }
    .breadcrumb-list {
      display: flex;
      flex-wrap: wrap;
      font-size: 0.875rem; }
      .breadcrumb-list .item {
        margin-bottom: 0.3125rem;
        color: #1774ff; }
        .breadcrumb-list .item:not(:last-child):after {
          content: ">";
          margin-left: 0.625rem;
          margin-right: 0.625rem;
          color: #AEAEAE; }
        .breadcrumb-list .item.active {
          color: #AEAEAE; }
        .breadcrumb-list .item .link:hover {
          color: #1774ff; }

.btn-black, .btn-grey, .btn-white, .btn-red {
  padding: 7px 15px 5px;
  transition: all .3s;
  min-width: 118px; }

.btn-white {
  background-color: #fff;
  color: #cb1b22;
  border: 1px solid #cb1b22;
  box-shadow: 2px 2px 20px -5px #000; }
  .btn-white:hover {
    background-color: #191c26;
    color: #fff;
    border-color: #fff; }

.btn-black {
  color: #fff;
  background-color: #000; }
  .btn-black:hover {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff; }

.btn-grey {
  background-color: #e8e8e8; }
  .btn-grey:hover {
    color: #fff;
    background-color: #000; }

.btn-red {
  border: 1px solid #cb1b22;
  color: #fff;
  background-color: transparent; }
  .btn-red:hover {
    background-color: #cb1b22;
    color: #fff; }
  .btn-red.style2 {
    background-color: #cb1b22;
    color: #fff;
    padding-bottom: 8px;
    min-width: 150px; }
    .btn-red.style2:hover {
      color: #cb1b22;
      background-color: transparent; }

.btn-def {
  padding: 10px 40px;
  background-color: #fff;
  border: 1px solid #a5a5a5;
  color: #515151;
  border-radius: 50px; }
  .btn-def:hover {
    box-shadow: 0 0 10px 0 #a5a5a5 inset; }

.btn-gray-border {
  background-color: #dedede;
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
  height: 100%;
  border-radius: 25px;
  color: #808080;
  transition: all .3s; }
  .btn-gray-border:hover {
    background-color: #cb1b22;
    color: #fff; }

.btn-main {
  background-color: #1774ff;
  padding: 10px 30px;
  text-align: center;
  color: #fff;
  text-transform: uppercase; }
  .btn-main:hover {
    color: #fff;
    background-color: rgba(23, 116, 255, 0.9); }

.dropdown {
  font-size: 13px; }
  .dropdown__btn {
    position: relative;
    border: 1px solid #ebebeb;
    padding: 3px 15px 3px 7px;
    cursor: pointer; }
    .dropdown__btn i {
      position: absolute;
      top: 50%;
      right: 7px;
      font-size: 15px;
      transform: translateY(-50%); }
  .dropdown__menu {
    border: 1px solid #e8e8e8;
    opacity: 0;
    transition: all .3s;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 3;
    background-color: #fff; }
  .dropdown.active .dropdown__menu {
    opacity: 1;
    visibility: visible; }
  .dropdown.active .dropdown__item a {
    height: 35px;
    padding: 3px 7px; }
  .dropdown.active i:before {
    content: "\f106"; }
  .dropdown__item ~ .dropdown__item a {
    border-top: 1px solid #e8e8e8; }
  .dropdown__item a {
    padding: 0 7px;
    height: 0;
    transition: all .3s;
    line-height: 30px;
    color: #bfbfbf; }
  @media screen and (min-width: 1024px) {
    .dropdown__item:hover a {
      background-color: #000;
      color: #fff; } }
  .dropdown__item.active a {
    background-color: #000;
    color: #fff; }

.form__sign-up-news {
  position: relative;
  display: flex;
  max-width: 320px; }
  .form__sign-up-news .form-control {
    border-radius: 5px 0 0 5px;
    border: 1px solid #353535;
    background-color: transparent;
    color: #aaa;
    padding: 7px 10px;
    font-size: 13px;
    border-right: 0;
    width: calc(100% - 40px); }
  .form__sign-up-news .btn-main {
    padding: 6px 15px;
    font-size: 13px;
    border-radius: 0 5px 5px 0; }

.form__search .form-control {
  border-radius: 25px;
  border: 2px solid #dedede;
  font-size: 12px;
  padding: 10px 20px;
  width: 100%;
  color: #000; }
  @media screen and (min-width: 768px) {
    .form__search .form-control {
      font-size: 14px; } }
  .form__search .form-control:focus {
    border-color: #cb1b22; }
  .form__search .form-control::placeholder {
    color: #afafaf; }

.footer {
  background: linear-gradient(135deg, #151617 0%, #0c2340 100%);
  color: #fff;
  font-size: 12px; }
  .footer .item {
    color: #e8e8e8; }
  .footer a:hover {
    color: #1774ff; }
  .footer .title {
    font-size: 16px;
    font-family: "gotham-regular"; }
    @media screen and (min-width: 1200px) {
      .footer .title {
        margin-bottom: 15px; } }
  .footer .contact-info i {
    padding-top: 4px; }
  .footer .contact-info .item {
    padding-bottom: 10px; }
  .footer__logo {
    margin-bottom: 10px; }
    .footer__logo img {
      height: 50px; }
  .footer .social-icons {
    margin-top: 15px; }
    .footer .social-icons .item a {
      position: relative;
      font-size: 14px;
      width: 30px;
      height: 30px;
      padding: 0;
      text-align: center;
      line-height: 30px;
      margin-right: 10px;
      color: #828282; }
      .footer .social-icons .item a:before {
        content: '';
        border: 1px solid #828282;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .footer .social-icons .item a:hover:before {
        border-color: #1774ff; }
    .footer .social-icons a:hover {
      color: #1774ff; }

body.nav-opened {
  overflow: hidden; }
  body.nav-opened:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9; }
  body.nav-opened .navigation-trigger {
    right: 290px; }

.navigation {
  position: relative; }
  .navigation, .navigation a {
    color: #fff; }
    @media screen and (min-width: 992px) {
      .navigation, .navigation a {
        color: #000; } }
    @media screen and (min-width: 1024px) {
      .navigation:hover, .navigation a:hover {
        color: #1774ff; } }
  .navigation .arrow {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    right: 0;
    z-index: 2; }
    @media screen and (min-width: 1024px) {
      .navigation .arrow {
        display: none; } }
    .navigation .arrow i {
      font-size: 14px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all .3s; }
    @media screen and (max-width: 991px) {
      .navigation .arrow {
        top: 7px;
        transform: translateY(0); } }
  .navigation-list > .list {
    padding: 10px 25px 10px 0; }
    @media screen and (min-width: 1024px) {
      .navigation-list > .list {
        padding-right: 0; }
        .navigation-list > .list:hover > a {
          color: #1774ff; }
        .navigation-list > .list:hover .nav-child a {
          color: #fff; }
          .navigation-list > .list:hover .nav-child a:hover {
            color: #000; } }
    .navigation-list > .list.active > a {
      color: #1774ff; }
    @media screen and (min-width: 992px) {
      .navigation-list > .list:last-child .list .nav-child, .navigation-list > .list:last-child .list.hover .nav-child {
        top: 0;
        left: unset;
        right: 100%; }
      .navigation-list > .list .list {
        padding: 0 25px 0 0; }
        .navigation-list > .list .list .nav-child {
          top: 0;
          left: 100%; } }
    @media screen and (min-width: 1024px) {
      .navigation-list > .list {
        margin: 0 10px; }
        .navigation-list > .list:last-child .list:hover .nav-child {
          top: 0;
          left: unset;
          right: 100%; } }
    @media screen and (max-width: 991px) {
      .navigation-list > .list {
        padding-right: 0;
        padding-left: 30px; } }
  .navigation-list .list {
    line-height: 20px; }
    @media screen and (min-width: 1024px) {
      .navigation-list .list:hover > .nav-child {
        visibility: visible;
        opacity: 1;
        height: auto; } }
    @media screen and (max-width: 1024px) {
      .navigation-list .list.hover > a, .navigation-list .list.hover > .arrow > i {
        color: #1774ff; }
      .navigation-list .list.hover > .arrow > i {
        transform: translate(-50%, -50%) rotate(90deg); }
      .navigation-list .list.hover > .nav-child {
        visibility: visible;
        opacity: 1;
        height: auto; } }
    @media screen and (max-width: 991px) {
      .navigation-list .list a {
        padding-right: 20px; } }
  .navigation-list .drop {
    position: relative; }
    .navigation-list .drop-list.show {
      transform: translate(0, 0); }
  @media screen and (max-width: 991px) {
    .navigation-list {
      margin-top: 40px;
      height: calc(100vh - 40px);
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 9;
      overflow-y: auto; }
      .navigation-list__item a {
        display: block;
        padding: 10px 0;
        border-bottom: 1px solid #b5b5b5; }
      .navigation-list .drop-list a {
        display: block;
        padding: 10px 0; } }
  .navigation .nav-child {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    z-index: 4;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    text-align: left; }
    @media screen and (min-width: 992px) {
      .navigation .nav-child {
        padding-top: 5px; } }
    .navigation .nav-child a {
      padding: 5px;
      display: block; }
    @media screen and (min-width: 992px) {
      .navigation .nav-child {
        left: 50%;
        transform: translateX(-50%);
        background-color: #1774ff;
        text-align: center;
        padding-bottom: 5px; }
        .navigation .nav-child a {
          color: #fff; } }
    @media screen and (min-width: 1024px) {
      .navigation .nav-child a:hover {
        color: #191c26; } }
    .navigation .nav-child a {
      padding: 5px 10px; }
  .navigation-open {
    width: 40px;
    height: 40px;
    position: relative; }
    .navigation-open::before, .navigation-open::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 25px; }
    .navigation-open::before {
      height: 15px;
      border-style: solid;
      border-color: #000 transparent;
      border-width: 1.5px 0; }
    .navigation-open::after {
      height: 2px;
      background-color: #000;
      z-index: 3; }
  .navigation-close {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px 0 30px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #090909;
    color: #fff; }
    .navigation-close:before {
      content: '';
      border-style: solid;
      border-width: 5px;
      border-color: transparent #fff transparent transparent;
      position: absolute;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px; }
  @media screen and (min-width: +576px) and (max-width: +991px) {
    .navigation {
      max-width: 450px; } }
  @media screen and (max-width: 991px) {
    .navigation {
      position: fixed;
      right: 0;
      top: 0;
      height: 100vh;
      width: 100%;
      z-index: 9;
      visibility: hidden;
      transform: rotateY(90deg);
      transform-origin: top right;
      transition: 500ms; }
      .navigation.nav-opened {
        transform: rotateY(0);
        visibility: visible; } }
  @media screen and (max-width: 991px) {
    .navigation .nav-child {
      position: static;
      height: 0;
      visibility: hidden;
      width: 100%; }
      .navigation .nav-child .nav-child {
        padding-left: 15px; } }

.header {
  font-size: 13px;
  position: relative; }
  .header__top {
    background: linear-gradient(45deg, #153bd6 0%, #1774ff 100%);
    padding-top: 5px;
    padding-bottom: 5px;
    color: #fff; }
  .header .logo {
    height: 50px;
    overflow: unset; }
  .header .search:before {
    content: '';
    width: 2px;
    height: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background-color: #7b7b7b; }
  .header .search .showSearch {
    cursor: pointer;
    width: 40px;
    height: 40px;
    position: relative; }
    .header .search .showSearch i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .header .search.active .form__sign-up-news {
    opacity: 1;
    visibility: visible; }
  .header .form__sign-up-news {
    position: absolute;
    top: 100%;
    z-index: 3;
    background: #efefef;
    border-radius: 5px;
    padding: 4px;
    width: 300px;
    right: 0;
    opacity: 0;
    visibility: hidden; }
    .header .form__sign-up-news .form-control {
      background-color: #fff; }

.lh-40 {
  line-height: 40px; }

.font-italic {
  font-style: italic; }

.text-underline {
  text-decoration: underline; }

.fs_12 {
  font-size: 12px; }

.fs_14 {
  font-size: 14px; }

.fs_16 {
  font-size: 15px; }
  @media screen and (min-width: 1200px) {
    .fs_16 {
      font-size: 16px; } }

.fs_18 {
  font-size: 16px; }
  @media screen and (min-width: 1200px) {
    .fs_18 {
      font-size: 18px; } }

.fs_20 {
  font-size: 18px; }
  @media screen and (min-width: 1200px) {
    .fs_20 {
      font-size: 20px; } }

.fs_24 {
  font-size: 18px; }
  @media screen and (min-width: 992px) {
    .fs_24 {
      font-size: 24px; } }

.fs_26 {
  font-size: 20px; }
  @media screen and (min-width: 992px) {
    .fs_26 {
      font-size: 22px; } }
  @media screen and (min-width: 1200px) {
    .fs_26 {
      font-size: 26px; } }

.fs_30 {
  font-size: 24px; }
  @media screen and (min-width: 768px) {
    .fs_30 {
      font-size: 28px; } }
  @media screen and (min-width: 992px) {
    .fs_30 {
      font-size: 30px; } }

.fs_37 {
  font-size: 30px; }
  @media screen and (min-width: 768px) {
    .fs_37 {
      font-size: 32px; } }
  @media screen and (min-width: 992px) {
    .fs_37 {
      font-size: 37px; } }

.fs_40 {
  font-size: 26px; }
  @media screen and (min-width: 992px) {
    .fs_40 {
      font-size: 30px; } }
  @media screen and (min-width: 1200px) {
    .fs_40 {
      font-size: 40px; } }

.fs_45 {
  font-size: 27px; }
  @media screen and (min-width: 992px) {
    .fs_45 {
      font-size: 35px; } }
  @media screen and (min-width: 1200px) {
    .fs_45 {
      font-size: 45px; } }

.fs_50 {
  font-size: 35px; }
  @media screen and (min-width: 992px) {
    .fs_50 {
      font-size: 45px; } }
  @media screen and (min-width: 1200px) {
    .fs_50 {
      font-size: 50px; } }

.fs_55 {
  font-size: 40px; }
  @media screen and (min-width: 992px) {
    .fs_55 {
      font-size: 45px; } }
  @media screen and (min-width: 1200px) {
    .fs_55 {
      font-size: 55px; } }

.fs_60 {
  font-size: 40px; }
  @media screen and (min-width: 992px) {
    .fs_60 {
      font-size: 55px; } }
  @media screen and (min-width: 1200px) {
    .fs_60 {
      font-size: 60px; } }

.selectbox {
  background-color: #fff;
  position: relative;
  height: 40px;
  display: inline-block;
  margin-bottom: 0;
  border: 1px solid #ddd;
  border-radius: 40px; }
  .selectbox .fa {
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    pointer-events: none; }
  .selectbox select {
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    appearance: none;
    padding-left: 10px;
    padding-right: 30px;
    width: 100%;
    height: 100%; }

.checkbox {
  user-select: none;
  display: inline-flex;
  align-items: baseline; }
  .checkbox-icon {
    border-radius: 4px;
    height: 16px;
    flex: 0 0 16px;
    max-width: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    border: 1px solid #c5c5c5; }
    .checkbox-icon:before {
      content: "\f00c";
      font-family: "FontAwesome";
      opacity: 0; }
  .checkbox-input {
    display: none; }
    .checkbox-input:checked + .checkbox-icon:before {
      opacity: 1; }
  .checkbox-text {
    flex: 0 0 calc(100% - 15px);
    max-width: calc(100% - 15px);
    padding-left: 10px; }
  .checkbox.radio .checkbox-icon {
    border-radius: 50%;
    position: relative;
    border-width: 1px; }
    .checkbox.radio .checkbox-icon:before {
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      content: ""; }

.formk .item {
  margin-bottom: 10px; }

.formk .form-input {
  width: 100%;
  border: 1px solid #c5c5c5;
  height: 40px;
  padding: 10px; }
  .formk .form-input.txt {
    height: 120px; }

.form-group .form-control {
  font-size: 14px;
  padding: 5px 15px; }
  .form-group .form-control:focus {
    border-color: #1774ff; }

.form-group textarea {
  height: 100px; }
  @media screen and (min-width: 992px) {
    .form-group textarea {
      height: 150px; } }

body {
  font-family: "gotham-regular";
  font-size: 0.875rem;
  color: #000;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1200px; } }

.nowrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.back2top {
  cursor: pointer;
  position: fixed;
  transition: all 200ms ease-in-out 200ms;
  z-index: 10;
  font-size: 24px;
  right: 15px;
  bottom: 17px;
  line-height: 0;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  padding-top: 3px;
  background-color: #1774ff;
  color: #fff;
  width: 34px;
  height: 34px; }
  .back2top.active {
    opacity: 1;
    visibility: visible; }
    .back2top.active:hover {
      background-color: #fff;
      border: 1px solid #1774ff;
      color: #1774ff; }

.hasLink {
  position: relative; }
  .hasLink > .link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 1; }

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.pt-10 {
  padding-top: 10px; }

.pt-15 {
  padding-top: 15px; }

.pt-20 {
  padding-top: 20px; }

.pt-30 {
  padding-top: 20px; }
  @media screen and (min-width: 992px) {
    .pt-30 {
      padding-top: 30px; } }

.pt-40, .section {
  padding-top: 30px; }
  @media screen and (min-width: 992px) {
    .pt-40, .section {
      padding-top: 40px; } }

.pt-70 {
  padding-top: 30px; }
  @media screen and (min-width: 768px) {
    .pt-70 {
      padding-top: 50px; } }
  @media screen and (min-width: 992px) {
    .pt-70 {
      padding-top: 70px; } }

.pb-10 {
  padding-bottom: 10px; }

.pb-15 {
  padding-bottom: 15px; }

.pb-20, .section {
  padding-bottom: 20px; }

.pb-30 {
  padding-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .pb-30 {
      padding-bottom: 30px; } }

.pb-40 {
  padding-bottom: 30px; }
  @media screen and (min-width: 992px) {
    .pb-40 {
      padding-bottom: 40px; } }

.pb-50 {
  padding-bottom: 30px; }
  @media screen and (min-width: 992px) {
    .pb-50 {
      padding-bottom: 40px; } }
  @media screen and (min-width: 1200px) {
    .pb-50 {
      padding-bottom: 50px; } }

.pb-70 {
  padding-bottom: 30px; }
  @media screen and (min-width: 768px) {
    .pb-70 {
      padding-bottom: 50px; } }
  @media screen and (min-width: 992px) {
    .pb-70 {
      padding-bottom: 70px; } }

.pl-15 {
  padding-left: 15px; }

.mt-30 {
  margin-top: 30px; }

.mt-40 {
  margin-top: 20px; }
  @media screen and (min-width: 768px) {
    .mt-40 {
      margin-top: 30px; } }
  @media screen and (min-width: 1200px) {
    .mt-40 {
      margin-top: 40px; } }

.mb-10 {
  margin-bottom: 10px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-25 {
  margin-bottom: 15px; }
  @media screen and (min-width: 992px) {
    .mb-25 {
      margin-bottom: 20px; } }
  @media screen and (min-width: 1200px) {
    .mb-25 {
      margin-bottom: 25px; } }

.mb-30 {
  margin-bottom: 10px; }
  @media screen and (min-width: 576px) {
    .mb-30 {
      margin-bottom: 30px; } }

.img_cen, .recruitment .post-block .post-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.img_full, .recruitment .post-block .post-image img, .slider-for .item img,
.slider-nav .item img {
  width: 100%;
  height: 100%; }

.img_fit, .recruitment .post-block .post-image img, .slider-for .item img,
.slider-nav .item img {
  object-fit: cover; }

.hasBg .link-bg {
  z-index: 1; }

@media screen and (min-width: 1024px) {
  .hasBg .link-bg {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0; }
  .hasBg:hover .link-bg {
    opacity: 1; } }

.pagination {
  justify-content: center; }
  .pagination .page-item .page-link {
    color: #365da6; }
  .pagination .page-item.active .page-link {
    background-color: #365da6;
    border-color: #365da6;
    color: #fff; }

.dots-style-none .slick-dots {
  display: none !important; }

.dots-style-1 .slick-dots {
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%; }
  @media screen and (min-width: 992px) {
    .dots-style-1 .slick-dots {
      bottom: 35px; } }
  .dots-style-1 .slick-dots button {
    width: 15px;
    height: 15px;
    position: relative;
    background-color: transparent;
    padding: 10px;
    border: 0;
    font-size: 0; }
    .dots-style-1 .slick-dots button::before {
      background-color: #cfcfcf;
      border: 0;
      content: '';
      font-size: 0;
      border-radius: 50%;
      width: 7px;
      height: 7px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-sizing: content-box;
      padding: 0; }
  .dots-style-1 .slick-dots li.slick-active button::before {
    background-color: rgba(206, 206, 206, 0.52); }

.dots-style-2 .slick-dots {
  bottom: -30px; }
  .dots-style-2 .slick-dots li button {
    width: 8px;
    height: 8px;
    background-color: #bbb;
    border-color: #BBB; }
  .dots-style-2 .slick-dots li.slick-active button {
    border-color: #1774ff; }

.nav-style-none .slick-arrow {
  display: none !important; }

.nav-style-1 .slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  background-color: transparent;
  opacity: 1;
  transition: all .3s;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 0;
  width: 30px;
  height: 30px;
  border: 0; }
  @media screen and (min-width: 576px) {
    .nav-style-1 .slick-arrow {
      width: 50px;
      height: 50px; } }
  .nav-style-1 .slick-arrow:before {
    border-width: 2px;
    border-style: solid;
    border-radius: 4px;
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg); }
    @media screen and (min-width: 576px) {
      .nav-style-1 .slick-arrow:before {
        width: 30px;
        height: 30px; } }

.nav-style-1 .slick-next {
  left: unset;
  right: 20px; }
  @media screen and (max-width: 991px) {
    .nav-style-1 .slick-next {
      right: 0; } }
  .nav-style-1 .slick-next::before {
    border-color: #fff #fff transparent transparent; }
  @media screen and (min-width: 1200px) {
    .nav-style-1 .slick-next {
      right: 30px; } }

.nav-style-1 .slick-prev {
  right: unset;
  left: 20px; }
  @media screen and (max-width: 991px) {
    .nav-style-1 .slick-prev {
      left: 0; } }
  .nav-style-1 .slick-prev::before {
    border-color: transparent transparent #fff #fff; }
  @media screen and (min-width: 1200px) {
    .nav-style-1 .slick-prev {
      left: 30px; } }

.nav-style-1 .slick-slider {
  z-index: 1; }

.nav-style-2 .slick-arrow {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  opacity: 1;
  transition: all .3s;
  cursor: pointer;
  font-size: 0;
  width: 40px;
  height: 30px;
  border: 0;
  bottom: 5px; }
  @media screen and (min-width: 768px) {
    .nav-style-2 .slick-arrow {
      bottom: 20px; } }
  @media screen and (min-width: 992px) {
    .nav-style-2 .slick-arrow {
      bottom: 50px; } }
  .nav-style-2 .slick-arrow:before {
    border-width: 2px;
    border-style: solid;
    border-radius: 3px;
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg); }

.nav-style-2 .slick-next {
  left: unset;
  right: 10px;
  border-radius: 0 20px 20px 0;
  border-left: 1px solid #999; }
  @media screen and (min-width: 576px) {
    .nav-style-2 .slick-next {
      right: 40px; } }
  .nav-style-2 .slick-next::before {
    border-color: #999 #999 transparent transparent; }
  @media screen and (min-width: 1200px) {
    .nav-style-2 .slick-next {
      right: 50px; } }

.nav-style-2 .slick-prev {
  left: unset;
  right: 50px;
  border-radius: 20px 0 0 20px; }
  @media screen and (min-width: 576px) {
    .nav-style-2 .slick-prev {
      right: 80px; } }
  .nav-style-2 .slick-prev::before {
    border-color: transparent transparent #999 #999; }
  @media screen and (min-width: 1200px) {
    .nav-style-2 .slick-prev {
      right: 90px; } }

.nav-style-2 .slick-slider {
  z-index: 1; }

@media screen and (min-width: 1024px) {
  .nav-style-2 .slick-arrow:hover {
    background-color: #1774ff; }
    .nav-style-2 .slick-arrow:hover.slick-prev::before {
      border-color: transparent transparent #fff #fff; }
    .nav-style-2 .slick-arrow:hover.slick-next::before {
      border-color: #fff #fff transparent transparent; } }

.nav-style-3 .slick-arrow {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #000;
  text-transform: uppercase;
  position: absolute;
  top: calc(100% + 10px);
  font-size: 12px;
  font-family: "gotham-light";
  padding-bottom: 5px; }
  .nav-style-3 .slick-arrow::before, .nav-style-3 .slick-arrow::after {
    font-family: FontAwesome; }
  .nav-style-3 .slick-arrow.slick-next {
    right: calc(50% - 70px); }
  .nav-style-3 .slick-arrow.slick-prev {
    left: calc(50% - 95px); }
  .nav-style-3 .slick-arrow.slick-next::before {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 2px;
    bottom: 0;
    transition: all .3s;
    background-color: #000; }
  .nav-style-3 .slick-arrow.slick-next::after {
    content: '\f061';
    padding-left: 5px; }
  .nav-style-3 .slick-arrow.slick-next:hover::before {
    width: 90%; }
  .nav-style-3 .slick-arrow.slick-prev::after {
    content: '';
    position: absolute;
    right: 0;
    width: 0;
    height: 2px;
    bottom: 0;
    transition: all .3s;
    background-color: #000; }
  .nav-style-3 .slick-arrow.slick-prev:hover::after {
    width: 90%; }
  .nav-style-3 .slick-arrow.slick-prev::before {
    content: '\f060';
    padding-right: 5px; }

/**
 * Main types of settings
 */
@media screen and (max-width: +767px) {
  .row-sm {
    margin-left: -5px;
    margin-right: -5px; }
    .row-sm > .item {
      padding-left: 5px;
      padding-right: 5px; } }

.sale-products .img:before {
  padding-top: 56.25%; }

.sale-products .img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.prod-aside--close {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  color: #fff;
  padding: 0 10px; }
  .prod-aside--close .fa {
    margin-right: 5px; }
  @media screen and (min-width: +992px) {
    .prod-aside--close {
      display: none; } }

.prod-aside--open {
  margin-bottom: 0.625rem; }
  @media screen and (min-width: +992px) {
    .prod-aside--open {
      display: none; } }

.prod-aside__block:not(:last-child) {
  margin-bottom: 30px; }

.prod-aside__block .title {
  font-size: 18px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  color: #000; }

.prod-aside__block .price {
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 10px; }
  .prod-aside__block .price-range {
    margin-bottom: 10px;
    background-color: #e9e9e9;
    border-color: #e9e9e9;
    height: 8px; }
  .prod-aside__block .price-val {
    display: flex;
    justify-content: space-between; }
  .prod-aside__block .price .ui-widget-header {
    background-color: #f48a00; }
  .prod-aside__block .price .ui-slider-handle {
    background-color: #f48a00;
    border-color: #f48a00;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    top: -4px;
    margin-left: -7px; }
  .prod-aside__block .price .ui-state-active {
    border-color: #f48a00; }

.prod-aside__block .checkbox {
  width: 100%; }
  .prod-aside__block .checkbox-list {
    max-height: 200px;
    overflow: hidden auto; }
    .prod-aside__block .checkbox-list .item {
      padding-top: 5px;
      padding-bottom: 5px; }

.prod-aside__block .search-input {
  border-radius: 30px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px; }

.prod-aside__nav {
  font-size: 16px;
  color: #656565; }
  .prod-aside__nav .list {
    transition: 350ms;
    overflow: hidden;
    flex: 0 0 100%;
    max-width: 100%; }
    .prod-aside__nav .list-child {
      border-top: 1px solid #e5e5e5;
      margin-top: 10px;
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 20px; }
      .prod-aside__nav .list-child > .item {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline; }
        .prod-aside__nav .list-child > .item:not(:last-child) {
          border-bottom: 1px solid #e5e5e5;
          padding-bottom: 10px; }
    .prod-aside__nav .list > .item {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 10px; }
      .prod-aside__nav .list > .item:not(:first-child) {
        padding-top: 10px; }
    .prod-aside__nav .list .drop-btn {
      flex: 0 0 30px;
      max-width: 30px;
      text-align: right;
      cursor: pointer; }
    .prod-aside__nav .list .drop > .link {
      padding-right: 30px;
      flex: 0 0 calc(100% - 30px);
      max-width: calc(100% - 30px); }
    .prod-aside__nav .list .drop > .drop-list {
      display: none; }
    .prod-aside__nav .list .drop.active > .drop-btn:before {
      content: "\f107"; }
    .prod-aside__nav .list .link:hover, .prod-aside__nav .list .link.active {
      color: #f48a00; }

.prod-aside__ads {
  margin-top: 1.875rem; }
  .prod-aside__ads:before {
    padding-top: 178.11159%; }
  .prod-aside__ads img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .prod-aside__ads img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%; }

@media screen and (max-width: +991px) {
  .prod-aside {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: top left;
    transform: rotateY(90deg);
    transition: 500ms;
    z-index: 9;
    background-color: #fff;
    padding: 0; }
    .prod-aside__wrap {
      box-shadow: initial;
      border-top: none;
      border-radius: 0;
      height: calc(100% - 40px);
      overflow-y: auto; }
    .prod-aside .titlek {
      display: none; }
    .prod-aside.active {
      transform: rotateY(0); } }

@media screen and (min-width: +576px) and (max-width: +991px) {
  .prod-aside {
    max-width: 270px; } }

.prod-info .gallery .large .img:before {
  padding-top: 133.33333%; }

.prod-info .gallery .large .img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.prod-info .gallery .large .img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%; }

.prod-info .gallery .thumbnail .slick-arrow:before {
  font-size: 20px;
  height: 30px;
  width: 16px; }

.prod-info .gallery .thumbnail .slick-slide {
  opacity: .5; }

.prod-info .gallery .thumbnail .slick-current {
  opacity: 1; }

.prod-info .info .name {
  font-size: 18px;
  display: block;
  margin-bottom: 5px; }

.prod-info .info .code {
  color: #858585;
  margin-bottom: 5px;
  margin-right: 10px; }

.prod-info .info .price {
  margin-bottom: 1rem; }
  .prod-info .info .price-current {
    color: #cb1b22;
    font-size: 28px;
    margin-right: 5px; }
  .prod-info .info .price-old {
    text-decoration: line-through;
    font-size: 14px; }
  .prod-info .info .price-percent {
    font-size: 14px; }

.prod-info .info .size-list {
  display: flex;
  flex-wrap: wrap; }

.prod-info .info .size .item {
  margin-right: 10px;
  margin-bottom: 5px; }

.prod-info .info .size .checkbox-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  border: 1px solid transparent;
  border-radius: 50%;
  padding: 0;
  flex: initial;
  max-width: initial; }

.prod-info .info .size .checkbox-input:checked ~ .checkbox-text {
  border-color: #858585; }

.prod-info .info .quantity {
  display: inline-flex;
  margin-bottom: 1rem; }
  .prod-info .info .quantity-btn {
    height: 44px;
    flex: 0 0 44px;
    max-width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #858585; }
  .prod-info .info .quantity-val {
    height: 44px;
    flex: 0 0 100px;
    max-width: 100px;
    text-align: center;
    border: 1px solid #858585;
    border-left: none;
    border-right: none; }

.prod-item .img {
  margin-bottom: .5rem; }
  .prod-item .img:before {
    padding-top: 136%; }
  .prod-item .img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .prod-item .img:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    opacity: 0;
    transition: 350ms;
    z-index: 1; }
  .prod-item .img-ctrl {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    opacity: 0;
    color: #f48a00;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 1; }

.prod-item .name {
  color: #515151; }
  .prod-item .name:hover {
    color: #f48a00; }

.prod-item .discount {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 25px;
  display: inline-block;
  transform: rotateZ(-90deg);
  transform-origin: top right;
  text-align: right; }

.prod-item .new {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 34px;
  width: 34px;
  background-color: #fe816d;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff; }

.prod-item .rating {
  margin-bottom: .5rem; }

.prod-item .price {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .prod-item .price-current {
    margin-right: .75rem;
    color: #e64242; }
  .prod-item .price-old {
    text-decoration: line-through; }

@media screen and (min-width: +768px) {
  .prod-item:hover .img:before {
    opacity: 1; }
  .prod-item:hover .img-ctrl {
    opacity: 1; }
  .prod-item .new {
    height: 44px;
    width: 44px; }
  .prod-item .img-ctrl {
    font-size: 20px;
    margin-bottom: 5px;
    margin-right: 10px;
    color: #fff;
    text-align: right; }
    .prod-item .img-ctrl .item {
      margin-top: 10px;
      flex: 0 0 100%;
      max-width: 100%; }
      .prod-item .img-ctrl .item:hover {
        color: #f48a00; } }

@media screen and (max-width: +767px) {
  .prod-item .img-ctrl {
    opacity: 1;
    color: #f48a00;
    background-color: rgba(255, 255, 255, 0.65);
    padding: 3px 5px; }
    .prod-item .img-ctrl .item:not(:last-child) {
      margin-right: 10px; } }

.prod-list > .item {
  margin-bottom: 10px; }
  @media screen and (min-width: +768px) {
    .prod-list > .item {
      margin-bottom: 30px; } }

.prod-summary .tab-content {
  padding: 20px 0; }

.prod-summary .comment-block + .comment-block {
  margin-top: 20px; }

.prod-summary .comment-block .name {
  color: #333; }

.prod-summary .comment-block .content {
  color: #656565; }

.prod-summary .comment-block .rating {
  font-size: 20px; }

.filter {
  display: flex;
  min-width: 180px;
  margin-bottom: 1rem;
  margin-left: auto; }
  .filter-wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between; }
    .filter-wrap .title {
      font-size: 18px;
      color: #515151; }
      .filter-wrap .title .number {
        font-size: 14px;
        color: #959595; }
  .filter .selectbox {
    width: 100%; }
    .filter .selectbox select {
      padding-left: 30px;
      padding-right: 40px; }
  @media screen and (max-width: +767px) {
    .filter-wrap {
      flex-wrap: wrap; } }

.tab-nav {
  border-bottom: 1px solid #858585; }
  .tab-nav .list {
    display: flex; }
  .tab-nav .link {
    padding: 20px 10px;
    border-bottom: 2px solid transparent; }
    .tab-nav .link.active {
      border-color: #000; }

.tab-panel {
  visibility: hidden;
  height: 0;
  overflow: hidden; }
  .tab-panel.faded {
    opacity: 0;
    transition: 500ms; }
  .tab-panel.active {
    visibility: visible;
    height: auto;
    opacity: 1; }

.mapbox:before {
  padding-top: 56.25%; }

.mapbox img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.mapbox iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100% !important; }

body {
  font-family: "gotham-regular";
  font-size: 14px;
  color: #000;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto; }
  body body:before,
  body .header:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    transition: all .3s; }

.container {
  max-width: 100%; }
  @media screen and (min-width: 1200px) {
    .container {
      max-width: 1200px; } }

@media screen and (max-width: 575px) {
  .container, .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-left: 5px;
    padding-right: 5px; } }

@media screen and (max-width: 575px) {
  .row {
    margin-left: -5px;
    margin-right: -5px; } }

.social-icons a {
  padding: 5px; }
  @media screen and (min-width: 768px) {
    .social-icons a {
      padding-left: 10px;
      padding-right: 10px; } }
  .social-icons a:hover {
    color: #191c26; }

/**
 * main-content
 */
.title-main {
  font-family: "gotham-bold";
  text-align: center;
  color: #1774ff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .title-main {
      margin-bottom: 30px; } }
  .title-main:before {
    content: '';
    width: 60px;
    height: 2px;
    background-color: #1774ff;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%); }

.nav {
  margin-bottom: 20px;
  font-size: 14px; }
  @media screen and (min-width: 992px) {
    .nav {
      margin-bottom: 25px; } }
  @media screen and (min-width: 1200px) {
    .nav {
      margin-bottom: 30px; } }
  .nav.style-1 {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center; }
  .nav .nav__item .nav__link {
    margin: 5px;
    display: block;
    text-transform: uppercase;
    padding: 5px 15px;
    position: relative;
    color: #b3b4b8;
    border: 1px solid #b3b4b8;
    border-radius: 20px; }
    @media screen and (min-width: 1200px) {
      .nav .nav__item .nav__link {
        padding: 7px 20px; } }
    .nav .nav__item .nav__link.active, .nav .nav__item .nav__link:hover {
      position: relative;
      color: #fff;
      border-color: #1774ff;
      background-color: #1774ff; }

.banner-home .style1 .item {
  position: relative;
  overflow: hidden; }
  .banner-home .style1 .item .embed-responsive:before {
    padding-top: 42.79131%; }
  .banner-home .style1 .item .embed-responsive img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .banner-home .style1 .item .content {
    padding: 10px;
    position: absolute;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.7);
    left: -100%;
    transform: translateX(-50%);
    z-index: 2;
    transition: all 1s; }
    @media screen and (min-width: 768px) {
      .banner-home .style1 .item .content {
        padding: 20px; } }
    @media screen and (min-width: 992px) {
      .banner-home .style1 .item .content {
        bottom: 90px; } }
    @media screen and (max-width: 991px) {
      .banner-home .style1 .item .content {
        top: 50%;
        transform: translate(-50%, -50%); } }
    @media screen and (max-width: 575px) {
      .banner-home .style1 .item .content .small,
      .banner-home .style1 .item .content .title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; } }
  .banner-home .style1 .item.slick-active .content {
    left: 50%; }

.product-new__item .embed-responsive {
  flex: 0 0 100px;
  max-width: 100px; }
  .product-new__item .embed-responsive:before {
    padding-top: 100%; }
  .product-new__item .embed-responsive img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .product-new__item .embed-responsive img {
    transition: all .3s; }
  @media screen and (min-width: 1024px) {
    .product-new__item .embed-responsive:hover img {
      transform: translate(-50%, -50%) scale(1.2); } }
  @media screen and (min-width: 1200px) {
    .product-new__item .embed-responsive {
      flex: 0 0 150px;
      max-width: 150px; } }

.product-new__item .content {
  flex: 0 0 calc(100% - 100px);
  max-width: calc(100% - 100px);
  padding-left: 10px; }
  .product-new__item .content .tag {
    color: #999;
    font-family: "gotham-light";
    margin-bottom: 5px; }
  .product-new__item .content .title {
    text-transform: uppercase;
    line-height: 1.4; }
  @media screen and (min-width: 1024px) {
    .product-new__item .content .tag:hover,
    .product-new__item .content .title:hover {
      color: #1774ff; } }
  @media screen and (min-width: 768px) {
    .product-new__item .content {
      padding-left: 25px; } }
  @media screen and (min-width: 1200px) {
    .product-new__item .content {
      flex: 0 0 calc(100% - 150px);
      max-width: calc(100% - 150px); } }

.product-highlight__item .embed-responsive {
  border-radius: 25px;
  overflow: hidden;
  margin-bottom: 10px; }
  .product-highlight__item .embed-responsive:before {
    padding-top: 100%; }
  .product-highlight__item .embed-responsive img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.product-highlight__item .content .title {
  text-transform: uppercase;
  text-align: center;
  color: #999;
  margin-bottom: 5px; }
  @media screen and (min-width: 1024px) {
    .product-highlight__item .content .title {
      padding-left: 5px;
      padding-right: 5px; } }
  .product-highlight__item .content .title a {
    font-size: 12px; }
    @media screen and (min-width: 576px) {
      .product-highlight__item .content .title a {
        font-size: 14px; } }
    @media screen and (min-width: 1200px) {
      .product-highlight__item .content .title a {
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; } }

.product-highlight__item .content .price {
  text-align: center;
  font-size: 12px; }
  @media screen and (min-width: 576px) {
    .product-highlight__item .content .price {
      font-size: 14px; } }
  @media screen and (min-width: 1200px) {
    .product-highlight__item .content .price {
      font-size: 16px; } }

@media screen and (min-width: 1024px) {
  .product-highlight__item {
    padding: 5px 5px 25px;
    position: relative; }
    .product-highlight__item::after {
      border-radius: 25px;
      content: '';
      box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1; }
    .product-highlight__item::before, .product-highlight__item::after {
      opacity: 0;
      transition: all .3s; }
    .product-highlight__item::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 70px;
      height: 2px;
      background-color: #1774ff;
      z-index: 2; }
    .product-highlight__item:hover::before, .product-highlight__item:hover::after {
      opacity: 1; } }

.news {
  padding-bottom: 70px; }
  .news__item {
    padding: 0 0 10px; }
    @media screen and (min-width: 360px) {
      .news__item {
        padding-left: 5px;
        padding-right: 5px; } }
    @media screen and (min-width: 992px) {
      .news__item {
        padding-left: 15px;
        padding-right: 15px; } }
    .news__item > .link {
      z-index: 4; }
    .news__item .title {
      font-size: 15px;
      line-height: 23px;
      max-height: 46px;
      overflow: hidden; }
    .news__item .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      color: #fff;
      opacity: 0; }
      .news__item .icon:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        border: 2px solid #fff;
        border-radius: 50%; }
    .news__item .embed-responsive:before {
      padding-top: 66.66667%; }
    .news__item .embed-responsive img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .news__item .embed-responsive .link {
      background-color: rgba(0, 0, 0, 0.2); }
    @media screen and (min-width: 1024px) {
      .news__item:hover .embed-responsive .link {
        opacity: 1; }
      .news__item:hover .icon {
        opacity: 1; }
      .news__item:hover .title {
        color: #1774ff; }
      .news__item .content {
        position: relative;
        width: calc(100% - 20px);
        left: 10px;
        margin-top: -40px;
        background-color: #fff;
        padding: 30px 15px 20px;
        z-index: 4;
        box-shadow: 0 2px 10px -2px #b3b3b3; }
        .news__item .content:before {
          content: "";
          width: 1px;
          height: 40px;
          position: absolute;
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 3;
          background-color: #000; } }

.btn-black {
  display: inline-block;
  text-transform: uppercase;
  color: #FFF;
  background: #191c26;
  border-radius: 0px;
  line-height: 30px;
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  z-index: 2;
  font-size: 14px;
  letter-spacing: .28px;
  text-align: center;
  transition: all 400ms linear 0s;
  transform: perspective(1px) translateZ(0);
  border: 0; }
  .btn-black::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #1774ff;
    left: 0px;
    top: 0px;
    display: block;
    transform-origin: 0 50%;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transform: scalex(0);
    transition: all 400ms linear 0s; }
  .btn-black:hover::before {
    transform: scaleY(1);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }

.contact-us .form-control {
  border: 1px solid #dedbdb;
  background: #f2f2f2;
  height: 45px;
  padding: 5px 15px;
  color: #797979; }

.contact-us textarea.form-control {
  height: 100px; }
  @media screen and (min-width: 1200px) {
    .contact-us textarea.form-control {
      height: 150px; } }

.contact-us .c_details_item {
  background: transparent;
  border: 1px solid #e5e5e5;
  padding: 15px;
  margin-bottom: 15px; }
  @media screen and (min-width: 992px) {
    .contact-us .c_details_item {
      padding: 28px 30px; } }
  .contact-us .c_details_item .media {
    display: table; }
    .contact-us .c_details_item .media .media-left,
    .contact-us .c_details_item .media .media-body {
      vertical-align: middle;
      display: table-cell; }
    .contact-us .c_details_item .media .media-left {
      padding-right: 15px; }
      @media screen and (min-width: 992px) {
        .contact-us .c_details_item .media .media-left {
          padding-right: 30px; } }
      .contact-us .c_details_item .media .media-left i {
        height: 40px;
        width: 40px;
        line-height: 38px;
        font-size: 18px;
        border: 1px solid #1774ff;
        background-color: #1774ff;
        border-radius: 50%;
        color: #FFF;
        text-align: center;
        transition: all 400ms linear 0s; }
        @media screen and (min-width: 992px) {
          .contact-us .c_details_item .media .media-left i {
            height: 50px;
            width: 50px;
            line-height: 48px;
            font-size: 22px; } }

.recruitment .hasLink > .link {
  z-index: 2; }

.recruitment .post-block {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  min-height: 200px;
  background: #000; }
  @media screen and (min-width: 992px) {
    .recruitment .post-block {
      min-height: 250px; } }
  .recruitment .post-block .post-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: .4;
    will-change: transform;
    transition: .66s all ease;
    overflow: hidden; }
    .recruitment .post-block .post-image img {
      transition: all .3s; }
  .recruitment .post-block .post-body {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 30px 22px 30px 25px;
    color: #fff; }
  @media screen and (min-width: 1024px) {
    .recruitment .post-block:hover .post-image img {
      transform: translate(-50%, -50%) scale(1.05); }
    .recruitment .post-block:hover .post-header {
      color: #1774ff; } }

.recruitment-detail .card {
  border: 0; }
  .recruitment-detail .card .table-custom td, .recruitment-detail .card .table-custom th {
    border-top: 0;
    border-bottom: 1px solid #6bc3f0; }
    @media screen and (max-width: 575px) {
      .recruitment-detail .card .table-custom td, .recruitment-detail .card .table-custom th {
        display: block;
        padding-left: 0;
        padding-right: 0; } }
  .recruitment-detail .card .btn-link {
    border-radius: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    position: relative;
    padding: 15px 30px 15px 15px;
    text-align: left;
    white-space: unset;
    color: #000; }
    .recruitment-detail .card .btn-link::before {
      content: "̶";
      color: #1774ff;
      font-family: "gotham-light";
      font-size: 28px;
      width: 8px;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%); }
    .recruitment-detail .card .btn-link, .recruitment-detail .card .btn-link:hover {
      text-decoration: none; }
    .recruitment-detail .card .btn-link.collapsed {
      border-bottom: 0; }
      .recruitment-detail .card .btn-link.collapsed::before {
        content: "+";
        width: unset; }
  .recruitment-detail .card:last-child .btn-link {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .recruitment-detail .card .card-body {
    font-family: "gotham-light"; }

@media screen and (max-width: 575px) {
  .news-page .content-left {
    height: 200px; } }

.news-page .wrapper-tag-list {
  color: #999;
  font-size: 14px;
  padding: 5px 0;
  border-top: 1px dashed #eee;
  border-bottom: 1px dashed #eee;
  margin-top: 10px;
  margin-bottom: 15px; }

.news-page .btn-readmore {
  text-transform: uppercase;
  padding: 7px 20px 6px;
  text-align: center;
  background: #f6f6f6;
  color: #3c3b48;
  border: 1px solid #ddd;
  margin-right: -2px; }
  .news-page .btn-readmore:hover {
    background-color: #1774ff;
    color: #fff; }

.news-page .social_icon .item {
  transition: background .3s; }
  .news-page .social_icon .item .link {
    border: 1px solid #ebebeb;
    text-align: center;
    width: 28px;
    height: 28px;
    display: block;
    color: #999;
    line-height: 26px; }
  .news-page .social_icon .item ~ .item {
    margin-left: 5px; }
  .news-page .social_icon .item:hover .link {
    color: #fff; }
  .news-page .social_icon .item.facebook:hover {
    background-color: #3b5998; }
  .news-page .social_icon .item.twitter:hover {
    background-color: #00aced; }
  .news-page .social_icon .item.google:hover {
    background-color: #dd4b39; }
  .news-page .social_icon .item.pinterest:hover {
    background-color: #cb2027; }

.news-page .item_title:hover {
  color: #1774ff; }

.news-detail-page .entry-date-text {
  color: #aaa; }

.news-detail-page .item_title:hover {
  color: #1774ff; }

.aside-menu.style1 .title {
  border-bottom: 2px solid #1774ff;
  padding-bottom: 10px;
  display: inline-block; }

.aside-menu.style1 .lists {
  background-color: #f4f4f4; }
  .aside-menu.style1 .lists .d-block {
    padding: 10px; }
    @media screen and (min-width: 992px) {
      .aside-menu.style1 .lists .d-block {
        padding: 15px; } }
    .aside-menu.style1 .lists .d-block:hover {
      color: #1774ff; }
  .aside-menu.style1 .lists .item ~ .item {
    border-top: 1px solid #fff; }

.aside-menu.style1 .item a.active {
  color: #1774ff; }

.btn-title {
  position: relative;
  font-size: 1rem;
  text-transform: uppercase;
  color: #fff;
  background: #1774ff;
  line-height: 50px;
  height: 50px;
  padding: 0 15px;
  letter-spacing: 1px;
  margin-bottom: 10px; }
  @media screen and (max-width: 991px) {
    .btn-title.toClose {
      padding-left: 45px; }
      .btn-title.toClose span {
        font-size: 35px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
        font-family: gotham-light; } }
  .btn-title.style2 {
    text-align: left;
    padding-left: 50px; }
    .btn-title.style2::before, .btn-title.style2::after {
      content: '';
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 25px; }
    .btn-title.style2::before {
      height: 15px;
      border-style: solid;
      border-color: #fff transparent;
      border-width: 1.5px 0; }
    .btn-title.style2::after {
      height: 2px;
      background-color: #fff;
      z-index: 3; }

@media screen and (max-width: 991px) {
  .product-sidebar {
    position: fixed;
    right: -320px;
    width: 320px;
    z-index: 99;
    top: 0;
    background: #fff;
    height: 100vh;
    transition: right .3s; }
    .product-sidebar .btn-title {
      margin: 15px;
      width: calc(100% - 30px); }
    .product-sidebar .filter-category {
      overflow: auto;
      height: calc(100vh - 81px);
      position: absolute;
      width: calc(100% - 30px);
      margin-top: 81px;
      left: 15px;
      top: 0;
      padding-bottom: 15px; }
    .product-sidebar.opened {
      right: 0; } }

.product-sidebar .filter-category > ul {
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb; }
  .product-sidebar .filter-category > ul > li {
    position: relative; }
    .product-sidebar .filter-category > ul > li > a {
      display: inline-block;
      padding: 10px 15px 10px 10px;
      color: #333;
      font-weight: 500;
      display: block;
      border-top: 1px solid #e6e6e6;
      background: whitesmoke; }

.product-sidebar .filter-category > ul > li .btn-showsubfilter {
  position: absolute;
  top: 8px;
  right: 0;
  width: 30px;
  text-align: center;
  cursor: pointer;
  transition: all, 0.3s;
  font-size: 18px;
  color: #666666; }

.product-sidebar .filter-category > ul > li.active > a {
  border-bottom: none;
  color: #1774ff; }

.product-sidebar .filter-category > ul > li .sub li a {
  display: block;
  padding: 5px 15px 5px 40px;
  position: relative;
  color: #666666; }

.product-sidebar .sub a {
  font-family: "gotham-light"; }

.product-sidebar .filter-category > ul > li .btn-showsubfilter.active {
  transform: rotateX(180deg); }

.product-sidebar .filter-category > ul > li .sub li a:before {
  content: '';
  display: block;
  position: absolute;
  top: 10px;
  left: 20px;
  width: 10px;
  height: 10px;
  border: 1px solid #999999;
  border-radius: 50%; }

.product-sidebar .filter-category > ul > li .sub li .btn-showsubfilter2.active {
  transform: rotateX(180deg); }

.product-sidebar .filter-category > ul > li .sub li .sub2 ul li a:before {
  content: '\f101';
  font-family: "FontAwesome";
  border: none;
  border-radius: 0;
  position: absolute;
  top: 5px;
  left: 20px; }

.product-sidebar .filter-category > ul > li .sub li .sub2 ul {
  padding-left: 20px; }

.product-sidebar .filter-category > ul > li .sub li {
  font-weight: normal;
  text-transform: none;
  padding: 0px 0px;
  font-size: 0.875rem;
  position: relative; }

.product-sidebar .filter-category > ul > li .sub li .btn-showsubfilter2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  text-align: center;
  cursor: pointer;
  transition: all, 0.3s;
  color: #666;
  font-size: 18px; }

.product-sidebar .filter-category > ul {
  border-bottom: 1px solid #e6e6e6; }

.product-sidebar .filter-category > ul > li .sub li.active > a {
  font-weight: 600;
  color: #1774ff; }

.product-sidebar .filter-category > ul > li .sub li.active > a:before {
  background: #1774ff;
  border-color: #1774ff; }

.product-sidebar .filter-category > ul > li .sub li .sub2 ul li.active a:before {
  background: none;
  border-color: transparent;
  color: #1774ff; }

.product-sidebar .filter-category > ul > li .btn-showsubfilter.active {
  transform: rotateX(180deg); }

.product-sidebar .filter-category > ul > li .sub {
  display: none;
  padding: 5px 0 10px; }
  .product-sidebar .filter-category > ul > li .sub.active {
    display: block; }

.product-sidebar .filter-category > ul > li .sub li .sub2 {
  display: none; }
  .product-sidebar .filter-category > ul > li .sub li .sub2.active {
    display: block; }

@media screen and (min-width: 1024px) {
  .product-sidebar .filter-category > ul > li .sub li .btn-showsubfilter2:hover,
  .product-sidebar .filter-category > ul > li .btn-showsubfilter:hover,
  .product-sidebar .filter-category > ul > li > a:hover,
  .product-sidebar .filter-category > ul > li .sub li:hover > a {
    color: #1774ff; } }

@media screen and (max-width: 767px) {
  .product-detail-page .description-detail {
    max-height: 480px;
    overflow: auto;
    border-bottom: 1px solid #dee2e6; } }

.product-info .product-sub {
  color: #666666; }

.product-info .product-code {
  margin-top: 10px;
  font-size: 0.875rem;
  color: #666666; }

.product-info hr {
  border-color: #333;
  width: 80px;
  margin: 13px 0 7px; }

.product-info .product-price {
  font-size: 1.375rem;
  color: #4d4d4d;
  text-transform: uppercase;
  margin-bottom: 20px; }

.product-info .share {
  margin-bottom: 20px; }

.product-info .share .text {
  color: #666666;
  display: inline-block;
  margin-right: 20px; }

.product-info .share a {
  display: inline-block;
  padding: 5px 10px; }

.product-info .share a.facebook {
  color: #3b5998; }

.product-info .share a.google {
  color: #d11718; }

.product-info .share a.twitter {
  color: #00c5f9; }

.product-info .btn-black {
  background: #3055d2;
  border-radius: 5px;
  overflow: hidden;
  text-transform: unset;
  padding-top: 4px; }

.slider-for .item:before,
.slider-nav .item:before {
  padding-top: 100%; }

.slider-for .item img,
.slider-nav .item img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.slider-for .item img,
.slider-nav .item img {
  overflow: hidden;
  border-radius: 20px; }

.slider-nav {
  margin: 10px 50px; }
  .slider-nav .item {
    cursor: pointer; }
    .slider-nav .item img {
      opacity: .4;
      padding: 5px; }
    .slider-nav .item.slick-current img {
      opacity: 1; }
  .slider-nav.nav-style-1 .slick-arrow {
    width: 30px;
    height: 30px;
    background-color: #191c26;
    border-radius: 50%; }
    .slider-nav.nav-style-1 .slick-arrow:hover {
      background-color: #1774ff; }
    .slider-nav.nav-style-1 .slick-arrow:before {
      width: 10px;
      height: 10px; }
  .slider-nav.nav-style-1 .slick-prev {
    left: -40px; }
    .slider-nav.nav-style-1 .slick-prev:before {
      left: 55%; }
  .slider-nav.nav-style-1 .slick-next {
    right: -40px; }
    .slider-nav.nav-style-1 .slick-next:before {
      left: 45%; }
