.pagination {
  justify-content: center;

  .page-item {
    .page-link {
      color: #365da6; }

    &.active {
      .page-link {
        background-color: #365da6;
        border-color: #365da6;
        color: #fff; } } } }
