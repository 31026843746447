// FONT FACE
@include font-face('gotham-bold', '../fonts/gotham/gotham-bold', normal, normal, ttf);
@include font-face('gotham-regular', '../fonts/gotham/gotham-regular', normal, normal, ttf);
@include font-face('gotham-italic', '../fonts/gotham/gotham-italic', normal, normal, ttf);
@include font-face('gotham-light', '../fonts/gotham/gotham-light', normal, normal, ttf);
@include font-face('gotham-thin', '../fonts/gotham/gotham-thin', normal, normal, ttf);
// +font-face('gotham-medium', '../fonts/gotham/gotham-medium', normal, normal, ttf)

$rem: 1rem/16;

// FONTS
$fr: 'gotham-regular';
$fb: 'gotham-bold';
$fi: 'gotham-italic';
$fl: 'gotham-light';

// COLORS
$cl_main: #1774ff;
$cl_white: #fff;
$cl_red: #cb1b22; //
$cl_black: #191c26;//
$cl_blue-light: #6bc3f0; //
$cl_brown: #9b9b9b;
$cl_gray: #e6e6e6; //
$cl_orange: #f48a00;

// LARGE SCREEN
// $unit: 100vw / 1920

.f {
  &r {
    font-family: $fr; }


  &b {
    font-family: $fb; }

  &i {
    font-family: $fi; }

  &l {
    font-family: $fl; }

  // &m
 }  //   font-family: $fm

.cl_ {
  &main {
    color: $cl_main; }

  &white {
    color: $cl_white; }

  &red {
    color: $cl_red; }

  &brown {
    color: $cl_brown; }

  &blue-light {
    color: $cl_blue-light; }

  &gray {
    color: $cl_gray; }

  &orange {
    color: $cl_orange; } }

.bg_ {
  &main {
    background-color: $cl_main; }

  &red {
    background-color: $cl_red; }

  &gray {
    background-color: $cl_gray; } }
