body {
	font-family: $fr;
	// background-color: $cl_gray
	font-size: 14*$rem;
	color: #000;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	// &:before
	// 	content: ""
	// 	position: absolute
	// 	top: 0
	// 	right: 0
	// 	width: 0
	// 	height: 100%
	// 	background-color: rgba(#000,.5)
	// 	transition: 350ms
	// 	z-index: 5

	// &.nav-opened
	// 	overflow: hidden

	// 	&:before
 }	// 		width: 100%

.container {
	@include min-xl {
		max-width: 1200px; } }

.nowrap {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis; }

.back2top {
	cursor: pointer;
	position: fixed;
	transition: all 200ms ease-in-out 200ms;
	z-index: 10;
	font-size: 24px;
	right: 15px;
	bottom: 17px;
	line-height: 0;
	opacity: 0;
	visibility: hidden;
	text-align: center;
	padding-top: 3px;
	background-color: $cl_main;
	color: #fff;
	width: 34px;
	height: 34px;

	&.active {
		opacity: 1;
		visibility: visible;

		&:hover {
			background-color: #fff;
			border: 1px solid $cl_main;
			color: $cl_main; } } }

// RATING STARS
// .rating
// 	position: relative
// 	font-size: 12px

// 	.numb
// 		// color: $cl_gray2
// 		font-size: 14px

// 	.rating-disabled .rating-stars
// 		cursor: default !important

// 	.rating-md
// 		font-size: unset !important

// 	.rating-container
// 		display: inline-block

// 		.empty-stars
// 			color: $cl_orange

// 	&.without-caption
// 		.theme-krajee-uni .star
// 			font-size: 20px
// 			margin: 0 1px
// 			line-height: 1

// 		.rating-container
// 			.clear-rating,
// 			.caption
// 				display: none


// 			.filled-stars
// 				-webkit-text-stroke: 0
// 				text-shadow: none
// 				color: $cl_orange

.hasLink {
	position: relative;

	&>.link {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: 0;
		z-index: 1; } }

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0; }

input[type="number"] {
	-moz-appearance: textfield; }
