// SELECT BOX
.selectbox {
    background-color: #fff;
    position: relative;
    height: 40px;
    display: inline-block;
    margin-bottom: 0;
    border: 1px solid #ddd;
    border-radius: 40px;

    .fa {
        position: absolute;
        right: 10px;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);
        pointer-events: none; }

    select {
        border: none;
        box-shadow: none;
        background: transparent;
        background-image: none;
        appearance: none;
        padding-left: 10px;
        padding-right: 30px;
        width: 100%;
        height: 100%; } }

// CHECKBOX
.checkbox {
    user-select: none;
    display: inline-flex;
    align-items: baseline;

    &-icon {
        border-radius: 4px;
        height: 16px;
        // width: 15px
        flex: 0 0 16px;
        max-width: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        // color: $cl_blue
        font-size: 10px;
        border: 1px solid #c5c5c5;

        &:before {
            content: "\f00c";
            font-family: "FontAwesome";
            opacity: 0; } }

    &-input {
        display: none;

        &:checked {
            &+.checkbox-icon {
                &:before {
                    opacity: 1; } } } }

    &-text {
        flex: 0 0 calc(100% - 15px);
        max-width: calc(100% - 15px);
        padding-left: 10px; }

    &.radio {
        .checkbox-icon {
            border-radius: 50%;
            position: relative;
            border-width: 1px;

            &:before {
                // background-color: $cl_blue
                width: calc(100% - 2px);
                height: calc(100% - 2px);
                @include align(cen);
                border-radius: 50%;
                content: ""; } } } }

// DOROPDOWN
// .dropdown2
//  .dropdown
//      &-btn
//          padding-right: 20px
//          position: relative
//          white-space: nowrap
//          overflow: hidden

//          +media(fhd, emp, 0)
//              padding-right: 20*$unit

//          &:before
//              content: ""
//              +align(ver)
//              right: 0
//              +arrow(bottom,5px,#939598)

// FORM
.form {
    // &-default
    //  &>.item
    //      &+.item
    //          margin-top: 10px

    //          +media(fhd, emp, 0)
    //              margin-top: 10*$unit

    //  .form
    //      &-input
    //          width: 100%
    //          height: 34px
    //          border: 1px solid #bbb
    //          padding: 6px 12px

    //          +media(fhd, emp, 0)
    //              height: 34*$unit
    //              border-width: 1*$unit
    //              padding: 6*$unit 12*$unit

    //          &:focus
    //              border-color: $cl_main
    //              color: $cl_main

    //      &-input::placeholder
    //          color: #bbb

    //      &-row
    //          &>.item
    //              padding-left: 5px
    //              padding-right: 5px

    //          +media(fhd, emp, 0)
    //              margin-left: -5*$unit
    //              margin-right: -5*$unit

    //              &>.item
    //                  padding-left: 5*$unit
    //                  padding-right: 5*$unit

    //          +media(xs,emp,-1)
    //              &>.item + .item
    //                  margin-top: 10px

    &k {
        .item {
            margin-bottom: 10px; }

        .form {
            &-text {}

            &-input {
                width: 100%;
                border: 1px solid #c5c5c5;
                height: 40px;
                padding: 10px;

                &.txt {
                    height: 120px; } } } }

    &-group {
        .form-control {
            &:focus {
                border-color: $cl_main; }

            // &
            font-size: 14px;
            padding: 5px 15px; }

        textarea {
            height: 100px;

            @include min-lg {
                height: 150px; } } } }
