.lh- {
  &40 {
    line-height: 40px; } }

.font- {
  &italic {
    font-style: italic; } }

.text-underline {
  text-decoration: underline; }

.fs_ {
  &12 {
    font-size: 12px; } //

  &14 {
    font-size: 14px; } //

  &16 {
    font-size: 15px;

    @include min-xl {
      font-size: 16px; } }

  &18 {
    font-size: 16px;

    @include min-xl {
      font-size: 18px; } }

  &20 {
    font-size: 18px;

    @include min-xl {
      font-size: 20px; } } //

  &24 {
    font-size: 18px; //

    @include min-lg {
      font-size: 24px; } }

  &26 {
    font-size: 20px;

    @include min-lg {
      font-size: 22px; }

    @include min-xl {
      font-size: 26px; } }

  &30 {
    font-size: 24px;

    @include min-md {
      font-size: 28px; }

    @include min-lg {
      font-size: 30px; } }

  &37 {
    font-size: 30px;

    @include min-md {
      font-size: 32px; }

    @include min-lg {
      font-size: 37px; } }

  &40 {
    font-size: 26px;

    @include min-lg {
      font-size: 30px; }

    @include min-xl {
      font-size: 40px; } }

  &45 {
    font-size: 27px;

    @include min-lg {
      font-size: 35px; }

    @include min-xl {
      font-size: 45px; } }

  &50 {
    font-size: 35px;

    @include min-lg {
      font-size: 45px; }

    @include min-xl {
      font-size: 50px; } }

  &55 {
    font-size: 40px;

    @include min-lg {
      font-size: 45px; }

    @include min-xl {
      font-size: 55px; } }

  &60 {
    font-size: 40px;

    @include min-lg {
      font-size: 55px; }

    @include min-xl {
      font-size: 60px; } } }
