//form
.form__ {
    &sign-up-news {
        position: relative;
        display: flex;
        max-width: 320px;

        // +max-xl
        //  // max-width: 500px

        .form-control {
            border-radius: 5px 0 0 5px;
            border: 1px solid #353535;
            background-color: transparent;
            color: #aaa;
            padding: 7px 10px;
            font-size: 13px;
            border-right: 0;
            width: calc(100% - 40px); }

        .btn-main {
            padding: 6px 15px;
            font-size: 13px;
            border-radius: 0 5px 5px 0; } }

    &search {
        .form-control {
            border-radius: 25px;
            border: 2px solid #dedede;
            font-size: 12px;
            padding: 10px 20px;
            width: 100%;
            color: #000;

            @include min-md {
                font-size: 14px; }

            &:focus {
                border-color: $cl_red; }

            &::placeholder {
                color: #afafaf; } } } }

.footer {
    // background-color: $cl_black
    background: linear-gradient(135deg, #151617 0%,#0c2340 100%);
    color: #fff;
    font-size: 12px;

    .item {
        color: #e8e8e8; }

    a:hover {
        color: $cl_main; }

    .title {
        font-size: 16px;
        font-family: $fr;

        @include min-xl {
            margin-bottom: 15px; } }

    .contact-info {
        i {
            padding-top: 4px; }

        .item {
            padding-bottom: 10px; } }

    &__logo {
        margin-bottom: 10px;

        img {
            height: 50px; } }

    .social-icons {
        margin-top: 15px;

        .item {
            a {
                position: relative;
                font-size: 14px;
                width: 30px;
                height: 30px;
                padding: 0;
                text-align: center;
                line-height: 30px;
                margin-right: 10px;
                color: #828282;

                &:before {
                    content: '';
                    border: 1px solid #828282;
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    @include align(cen); }

                &:hover {
                    &:before {
                        border-color: $cl_main; } } } }

        a:hover {
            color: $cl_main; } }

    &__top {
        // padding-top: 20px
 } }        // padding-bottom: 20px

body {
    &.nav-opened {
        overflow: hidden;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0,0,0,.6);
            z-index: 9; }

        .navigation-trigger {
            right: 290px; } } }

//navigation
.navigation {
    position: relative;

    &, a {
        color: #fff;

        @include min-lg {
            color: #000; }

        @include min(1024px) {
            &:hover {
                color: $cl_main; } } }

    .arrow {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        right: 0;
        z-index: 2;

        @include min(1024px) {
            display: none; }

        i {
            font-size: 14px;
            @include align(cen);
            transition: all .3s; }

        @include max-lg {
            top: 7px;
            transform: translateY(0); } }

    &-list {
        >.list {
            padding: 10px 25px 10px 0;

            @include min(1024px) {
                padding-right: 0;

                &:hover {
                    >a {
                        color: $cl_main; }

                    .nav-child {
                        // padding-top: 5px
                        // padding-bottom: 5px

                        a {
                            color: #fff;

                            &:hover {
                                color: #000; } } } } }

            &.active {
                >a {
                    color: $cl_main; } }

            @include min-lg {
                &:last-child {
                    .list, .list.hover {
                        .nav-child {
                            top: 0;
                            left: unset;
                            right: 100%; } } }

                .list {
                    padding: 0 25px 0 0;

                    .nav-child {
                        // padding-top: 5px
                        // padding-bottom: 5px
                        top: 0;
                        left: 100%; } } }

            @include min(1024px) {
                margin: 0 10px;

                &:last-child {
                    .list:hover {
                        .nav-child {
                            top: 0;
                            left: unset;
                            right: 100%; } } } }

            @include max-lg {
                padding-right: 0;
                padding-left: 30px; } }

        .list {
            line-height: 20px;

            @include min(1024px) {
                &:hover {
                    >.nav-child {
                        visibility: visible;
                        opacity: 1;
                        height: auto; } } }

            @include max(1024px) {
                &.hover {
                    >a, >.arrow >i {
                        color: $cl_main; }

                    >.arrow >i {
                        transform: translate(-50%, -50%) rotate(90deg); }

                    >.nav-child {
                        visibility: visible;
                        opacity: 1;
                        height: auto; } } }

            @include max-lg {
                a {
                    padding-right: 20px; } } }

        .drop {
            position: relative;

            &-list {
                &.show {
                    transform: translate(0,0); } } }

        // +media(sm,emp,-1)
        @include max-lg {
            margin-top: 40px;
            height: calc(100vh - 40px);
            width: 100%;
            background-color: rgba(#000,0.5);
            z-index: 9;
            overflow-y: auto;

            &__item {
                a {
                    display: block;
                    padding: 10px 0;
                    border-bottom: 1px solid #b5b5b5; } }

            .drop {
                &-list {
                    a {
                        display: block;
                        padding: 10px 0; } } } } }

    .nav-child {
        position: absolute;
        top: 100%;
        left: 0;
        width: 200px;
        z-index: 4;
        visibility: hidden;
        opacity: 0;
        transition: all .3s;
        text-align: left;

        @include min-lg {
            padding-top: 5px;
 }            // padding-bottom: 5px

        a {
            padding: 5px;
            display: block; }

        @include min-lg {
            left: 50%;
            transform: translateX(-50%);
            background-color: $cl_main;
            text-align: center;
            padding-bottom: 5px;

            a {
                color: #fff; } }

        @include min(1024px) {
            a:hover {
                color: $cl_black; } }

        a {
            padding: 5px 10px; } }

    &-open {
        // align-items: center
        // justify-content: center
        // color: #fff
        // background-color: $cl_red
        // font-size: 16px
        // padding: 5px 30px
        // margin: 10px 0
        // float: right
        // border: 1px solid #fff
        width: 40px;
        height: 40px;
        position: relative;

        &::before,
        &::after {
            content: '';
            @include align(cen);
            width: 25px; }

        &::before {
            height: 15px;
            border: {
                style: solid;
                color: #000 transparent;
                width: 1.5px 0; } }

        &::after {
            height: 2px;
            background-color: #000;
            z-index: 3; }

        // line-height: 1
        // padding: 9px 25px 10px

        // +max-md
        //     order: 2
 }        //     margin-left: 10px

    &-close {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 20px 0 30px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #090909;
        color: #fff;

        &:before {
            content: '';
            border: {
                style: solid;
                width: 5px;
                color: transparent #fff transparent transparent; }
            position: absolute;
            @include align(ver);
            left: 10px; } }

    @include media(xs,md,0) {
        max-width: 450px; }

    // +media(sm,emp,-1)
    @include max-lg {
        position: fixed;
        right: 0;
        top: 0;
        height: 100vh;
        width: 100%;
        z-index: 9;
        visibility: hidden;
        transform: rotateY(90deg);
        transform-origin: top right;
        transition: 500ms;

        &.nav-opened {
            transform: rotateY(0);
            visibility: visible; } }

    @include max-lg {
        .nav-child {
            position: static;
            height: 0;
            visibility: hidden;
            width: 100%;

            .nav-child {
                padding-left: 15px; } } } }

.header {
    // background-color: $cl_black
    // color: #fff
    font-size: 13px;
    position: relative;
    // margin-bottom: -1px

    // position: fixed
    // top: 0
    // left: 0
    // width: 100%
    // z-index: 99

    &__top {
        background: linear-gradient(45deg, #153bd6 0%,#1774ff 100%);
        padding-top: 5px;
        padding-bottom: 5px;
        color: #fff; }

    .logo {
        // +img_box(3, 1)
        height: 50px;
        overflow: unset; }

    .search {
        &:before {
            content: '';
            width: 2px;
            height: 15px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            background-color: #7b7b7b; }

        .showSearch {
            cursor: pointer;
            width: 40px;
            height: 40px;
            position: relative;

            i {
                @include align(cen); } }

        &.active {
            .form__sign-up-news {
                opacity: 1;
                visibility: visible; } } }

    .form__sign-up-news {
        position: absolute;
        top: 100%;
        z-index: 3;
        background: #efefef;
        border-radius: 5px;
        padding: 4px;
        width: 300px;
        right: 0;
        opacity: 0;
        visibility: hidden;

        .form-control {
            background-color: #fff; }

 } }        // .btn-main
