.dropdown {
  font-size: 13px;

  &__btn {
    position: relative;
    border: 1px solid #ebebeb;
    padding: 3px 15px 3px 7px;
    cursor: pointer;

    i {
      position: absolute;
      top: 50%;
      right: 7px;
      font-size: 15px;
      transform: translateY(-50%); } }

  &__menu {
    border: 1px solid #e8e8e8;
    opacity: 0;
    transition: all .3s;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 3;
    background-color: #fff; }

  &.active {
    .dropdown__menu {
      opacity: 1;
      visibility: visible; }

    .dropdown__item {
      a {
        height: 35px;
        padding: 3px 7px; } }

    i {
      &:before {
        content: "\f106"; } } }

  &__item {
    & ~ .dropdown__item {
      a {
        border-top: 1px solid #e8e8e8; } }

    a {
      padding: 0 7px;
      height: 0;
      transition: all .3s;
      line-height: 30px;
      color: #bfbfbf; }

    @include min(1024px) {
      &:hover {
        a {
          background-color: #000;
          color: #fff; } } }

    &.active {
      a {
        background-color: #000;
        color: #fff; } } } }
