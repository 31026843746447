.p {
  &t- {
    &10 {
      padding-top: 10px; }

    &15 {
      padding-top: 15px; }

    &20 {
      padding-top: 20px; }

    &30 {
      padding-top: 20px;

      @include min-lg {
        padding-top: 30px; } }

    &40 {
      padding-top: 30px;

      @include min-lg {
        padding-top: 40px; } }

    &70 {
      padding-top: 30px;

      @include min-md {
        padding-top: 50px; }

      @include min-lg {
        padding-top: 70px; } } }

  &b- {
    &10 {
      padding-bottom: 10px; }

    &15 {
      padding-bottom: 15px; }

    &20 {
      padding-bottom: 20px; }

    &30 {
      padding-bottom: 20px;

      @include min-lg {
        padding-bottom: 30px; } }

    &40 {
      padding-bottom: 30px;

      @include min-lg {
        padding-bottom: 40px; } }

    &50 {
      padding-bottom: 30px;

      @include min-lg {
        padding-bottom: 40px; }

      @include min-xl {
        padding-bottom: 50px; } }

    &70 {
      padding-bottom: 30px;

      @include min-md {
        padding-bottom: 50px; }

      @include min-lg {
        padding-bottom: 70px; } } }

  &l- {
    &15 {
      padding-left: 15px; } } }

.m {
  &t- {
    &30 {
      margin-top: 30px; }

    &40 {
      margin-top: 20px;

      @include min-md {
        margin-top: 30px; }

      @include min-xl {
        margin-top: 40px; } } }

  &b- {
    &10 {
      margin-bottom: 10px; }

    &20 {
      margin-bottom: 20px; }

    &25 {
      margin-bottom: 15px;

      @include min-lg {
        margin-bottom: 20px; }

      @include min-xl {
        margin-bottom: 25px; } }

    &30 {
      margin-bottom: 10px;

      @include min-sm {
        margin-bottom: 30px; } } } }
