.dots-style- {
  &none {
    .slick-dots {
      display: none !important; } }

  &1 {
    .slick-dots {
      @extend .reset-list;
      bottom: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: absolute;
      left: 0;
      width: 100%;

      @include min-lg {
        bottom: 35px; }

      // +media(fhd, emp, 0)
      //// bottom: 15*$unit

      button {
        width: 15px;
        height: 15px;
        position: relative;
        background-color: transparent;
        padding: 10px;
        border: 0;
        font-size: 0;

        // +media(fhd, emp, 0)
        //   width: 20*$unit
        //   height: 20*$unit

        &::before {
          background-color: rgba(207, 207, 207, 1);
          border: 0;
          content: '';
          font-size: 0;
          border-radius: 50%;
          width: 7px;
          height: 7px;
          display: block;
          @include align(cen);
          box-sizing: content-box;
          padding: 0;

          @include min-lg {
            // width: 10px
 }            // height: 10px

          // +media(fhd, emp, 0)
          //   width: 10*$unit
          //   height: 10*$unit
 } }          //   border-width: 1*$unit

      li {
        &.slick-active {
          button {
            &::before {
              background-color: rgba(206, 206, 206, 0.52); } } } } } }

  &2 {
    .slick-dots {
      bottom: -30px;

      // +media(fhd, emp, 0)
      //   bottom: -30*$unit

      li {
        button {
          width: 8px;
          height: 8px;
          background-color: #bbb;
          border-color: #BBB;

          // +media(fhd, emp, 0)
          //   width: 8*$unit
 }          //   height: 8*$unit

        &.slick-active {
          button {
            border-color: $cl_main; } } } } } }

.nav-style- {
  &none {
    .slick-arrow {
      display: none !important; } }

  &1 {
    .slick-arrow {
      position: absolute;
      top: 50%;
      z-index: 1;
      background-color: transparent;
      opacity: 1;
      transition: all .3s;
      transform: translateY(-50%);
      cursor: pointer;
      font-size: 0;
      width: 30px;
      height: 30px;
      border: 0;

      @include min-sm {
        width: 50px;
        height: 50px; }

      &:before {
        border: {
          width: 2px;
          style: solid; }
        border-radius: 4px;
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);

        @include min-sm {
          width: 30px;
          height: 30px; } } }

    .slick-next {
      left: unset;
      right: 20px;

      @include max-lg {
        right: 0; }

      &::before {
        border-color: #fff #fff transparent transparent; }

      @include min-xl {
        right: 30px; }

      // &:before
 }      //// content: ''

    .slick-prev {
      right: unset;
      left: 20px;

      @include max-lg {
        left: 0; }

      &::before {
        border-color: transparent transparent #fff #fff; }

      @include min-xl {
        left: 30px; }

      // &:before
 }      //// content: ''

    .slick-slider {
      z-index: 1; } }

  &2 {
    .slick-arrow {
      position: absolute;
      // top: 50%
      z-index: 1;
      background-color: #fff;
      opacity: 1;
      transition: all .3s;
      // transform: translateY(-50%)
      cursor: pointer;
      font-size: 0;
      width: 40px;
      height: 30px;
      border: 0;
      bottom: 5px;

      @include min-md {
        bottom: 20px; }

      @include min-lg {
        bottom: 50px;
        // width: 50px
 }        // height: 50px

      &:before {
        border: {
          width: 2px;
          style: solid; }
        border-radius: 3px;
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);

        // +min-sm
        //   width: 10px
 } }        //   height: 10px

    .slick-next {
      left: unset;
      right: 10px;
      border-radius: 0 20px 20px 0;
      border-left: 1px solid #999;

      @include min-sm {
        right: 40px; }

      // +max-lg
      //// right: 0

      &::before {
        border-color: #999 #999 transparent transparent; }

      @include min-xl {
        right: 50px; }

      // &:before
 }      //// content: ''

    .slick-prev {
      left: unset;
      right: 50px;
      border-radius: 20px 0 0 20px;

      // +max-lg
      //   left: 0

      @include min-sm {
        right: 80px; }

      &::before {
        border-color: transparent transparent #999 #999; }

      @include min-xl {
        right: 90px; }

      // &:before
 }      //// content: ''

    .slick-slider {
      z-index: 1; }

    @include min(1024px) {
      .slick-arrow {
        &:hover {
          background-color: $cl_main;

          &.slick-prev {
            &::before {
              border-color: transparent transparent #fff #fff; } }

          &.slick-next {
            &::before {
              border-color: #fff #fff transparent transparent; } } } } } }

  &3 {
    .slick-arrow {
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid #000;
      text-transform: uppercase;
      position: absolute;
      top: calc(100% + 10px);
      font-size: 12px;
      font-family: $fl;
      padding-bottom: 5px;

      &::before,
      &::after {
        font-family: FontAwesome; }

      &.slick-next {
        // right: 43.5%
        right: calc(50% - 70px); }

      &.slick-prev {
        // left: 42%
        left: calc(50% - 95px); }

      &.slick-next {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: 0;
          height: 2px;
          bottom: 0;
          transition: all .3s;
          background-color: #000; }

        &::after {
          content: '\f061';
          padding-left: 5px; }

        &:hover {
          &::before {
            width: 90%; } } }

      &.slick-prev {
        &::after {
          content: '';
          position: absolute;
          right: 0;
          width: 0;
          height: 2px;
          bottom: 0;
          transition: all .3s;
          background-color: #000; }

        &:hover {
          &::after {
            width: 90%; } }

        &::before {
          content: '\f060';
          padding-right: 5px; } } } } }
