.b {
  &t {
    &-1 {
      border-top: 1px solid #ebebeb; } }

  &b {
    &-1 {
      border-bottom: 1px solid #ebebeb; } }

  &r {
  	&-1 {
      border-right: 1px solid #ebebeb; } }

  &l {
  	&-1 {
      border-left: 1px solid #ebebeb; } } }
