.img_ {
  &cen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

  &full {
    width: 100%;
    height: 100%; }

  &fit {
    object-fit: cover; } }
