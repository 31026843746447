// .hasLink
//   position: relative

//   .link
//     position: absolute
//     top: 0
//     left: 0
//     width: 100%
//     height: 100%
//     z-index: 1

.hasBg {
  .link-bg {
    z-index: 1; }

  @include min(1024px) {
    .link-bg {
      background-color: rgba(#000, .5);
      opacity: 0; }

    &:hover {
      .link-bg {
        opacity: 1; } } } }
