// ROW
.row {
	&-sm {
		@include media(sm, emp, -1) {
			margin-left: -5px;
			margin-right: -5px;

			&>.item {
				padding-left: 5px;
				padding-right: 5px; } } } }

.sale-products {
	.img {
		@include img_box(16,9); } }

// PRODUCTS
.prod {
	&-aside {
		&--close {
			display: flex;
			align-items: center;
			height: 40px;
			width: 100%;
			color: #fff;
			padding: 0 10px;
			// background-color: $cl_blue

			.fa {
				margin-right: 5px; }

			@include media(md, emp, 0) {
				display: none; } }

		&--open {
			margin-bottom: 10*$rem;

			@include media(md, emp, 0) {
				display: none; } }

		&__wrap {}


		&__block {
			&:not(:last-child) {
				margin-bottom: 30px; }

			.title {
				font-size: 18px;
				padding-bottom: 10px;
				margin-bottom: 10px;
				border-bottom: 1px solid #e5e5e5;
				color: #000; }

			// &.drop
			// 	.title
			// 		display: flex
			// 		align-items: baseline

			// 		.drop
			// 			&-btn
			// 				flex: 0 0 30px
			// 				max-width: 30px
			// 				text-align: right
			// 				margin-left: auto

			// 	&.active
			// 		.title
			// 			.drop
			// 				&-btn
			// 					&:before
			// 						content: "\f068"

			.price {
				padding-left: 7px;
				padding-right: 7px;
				padding-top: 10px;

				&-range {
					margin-bottom: 10px;
					background-color: #e9e9e9;
					border-color: #e9e9e9;
					height: 8px; }

				&-val {
					display: flex;
					justify-content: space-between; }

				.ui {
					&-widget-header {
						background-color: $cl_orange; }

					&-slider-handle {
						background-color: $cl_orange;
						border-color: $cl_orange;
						border-radius: 50%;
						height: 14px;
						width: 14px;
						top: -4px;
						margin-left: -7px; }

					&-state-active {
						border-color: $cl_orange; } } }

			.checkbox {
				width: 100%;

				&-list {
					max-height: 200px;
					overflow: hidden auto;

					.item {
						padding-top: 5px;
						padding-bottom: 5px; } } }

			.search-input {
				border-radius: 30px;
				margin-bottom: 20px;
				padding-left: 20px;
				padding-right: 20px; } }

		&__nav {
			font-size: 16px;
			color: #656565;
			// display: none

			.list {
				transition: 350ms;
				overflow: hidden;
				flex: 0 0 100%;
				max-width: 100%;

				&-child {
					border-top: 1px solid #e5e5e5;
					margin-top: 10px;
					flex: 0 0 100%;
					max-width: 100%;
					padding-left: 20px;

					&>.item {
						padding-top: 10px;
						display: flex;
						flex-wrap: wrap;
						align-items: baseline;

						&:not(:last-child) {
							border-bottom: 1px solid #e5e5e5;
							padding-bottom: 10px; } } }

				&>.item {
					display: flex;
					flex-wrap: wrap;
					align-items: baseline;
					border-bottom: 1px solid #e5e5e5;
					padding-bottom: 10px;

					&:not(:first-child) {
						padding-top: 10px; } }

				.drop {
					&-btn {
						flex: 0 0 30px;
						max-width: 30px;
						text-align: right;
						cursor: pointer; }

					&>.link {
						padding-right: 30px;
						flex: 0 0 calc(100% - 30px);
						max-width: calc(100% - 30px); }

					&>.drop-list {
						display: none; }

					&.active {
						&>.drop {
							&-btn {
								&:before {
									content: "\f107"; } } } } }

				.link {
					&:hover,
					&.active {
						color: $cl_orange; } } } }

		&__ads {
			@include img_box(233,415);
			margin-top: 30*$rem;

			img {
				@include align(cen);
				height: 100%;
				width: 100%; } }

		@include media(md, emp, -1) {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform-origin: top left;
			transform: rotateY(90deg);
			transition: 500ms;
			z-index: 9;
			background-color: #fff;
			padding: 0;

			&__wrap {
				box-shadow: initial;
				border-top: none;
				border-radius: 0;
				height: calc(100% - 40px);
				overflow-y: auto; }

			.titlek {
				display: none; }

			&.active {
				transform: rotateY(0); } }

		@include media(xs, md, 0) {
			max-width: 270px; } }

	&-info {
		.gallery {
			.large {
				.img {
					@include img_box(600,800);

					img {
						@include align(cen);
						height: 100%;
						width: 100%; } } }

			.thumbnail {
				.slick {
					&-arrow {
						&:before {
							font-size: 20px;
							height: 30px;
							width: 16px; } }

					&-slide {
						opacity: .5; }

					&-current {
						opacity: 1; } } } }

		.info {
			.name {
				font-size: 18px;
				display: block;
				margin-bottom: 5px; }

			.code {
				color: #858585;
				margin-bottom: 5px;
				margin-right: 10px; }

			.price {
				margin-bottom: 1rem;

				&-current {
					color: $cl_red;
					font-size: 28px;
					margin-right: 5px; }

				&-old {
					text-decoration: line-through;
					font-size: 14px; }

				&-percent {
					font-size: 14px; } }

			.size {
				&-list {
					display: flex;
					flex-wrap: wrap; }

				.item {
					margin-right: 10px;
					margin-bottom: 5px; }

				.checkbox {
					&-text {
						display: flex;
						align-items: center;
						justify-content: center;
						height: 34px;
						width: 34px;
						border: 1px solid transparent;
						border-radius: 50%;
						padding: 0;
						flex: initial;
						max-width: initial; }

					&-input {
						&:checked {
							&~.checkbox-text {
								border-color: #858585; } } } } }

			.quantity {
				display: inline-flex;
				margin-bottom: 1rem;

				&-btn {
					height: 44px;
					flex: 0 0 44px;
					max-width: 44px;
					display: flex;
					align-items: center;
					justify-content: center;
					border: 1px solid #858585; }

				&-val {
					height: 44px;
					flex: 0 0 100px;
					max-width: 100px;
					text-align: center;
					border: 1px solid #858585;
					border-left: none;
					border-right: none; } } } }

	&-item {
		.img {
			@include img_box(250,340);
			margin-bottom: .5rem;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background-color: rgba(#000, .65);
				opacity: 0;
				transition: 350ms;
				z-index: 1; }

			&-ctrl {
				position: absolute;
				bottom: 0;
				right: 0;
				z-index: 2;
				opacity: 0;
				color: $cl_orange;
				display: flex;
				flex-wrap: wrap;
				font-size: 14px;
				line-height: 1; } }

		.name {
			color: #515151;

			&:hover {
				color: $cl_orange; } }

		.discount {
			position: absolute;
			top: 0;
			right: 0;
			margin-top: 10px;
			margin-right: 25px;
			display: inline-block;
			transform: rotateZ(-90deg);
			transform-origin: top right;
			text-align: right;
 }			// font-size: 12px

		.new {
			position: absolute;
			top: 5px;
			left: 5px;
			height: 34px;
			width: 34px;
			background-color: #fe816d;
			font-size: 12px;
			// font-family: $fb
			text-transform: uppercase;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			color: #fff; }

		.rating {
			margin-bottom: .5rem; }

		.price {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			&-current {
				margin-right: .75rem;
				color: #e64242; }

			&-old {
				text-decoration: line-through; } }

		@include media(sm, emp, 0) {
			&:hover {
				.img {
					&:before {
						opacity: 1; }

					&-ctrl {
						opacity: 1; } } }

			.new {
				height: 44px;
				width: 44px; }

			.img {
				&-ctrl {
					font-size: 20px;
					margin-bottom: 5px;
					margin-right: 10px;
					color: #fff;
					text-align: right;

					.item {
						margin-top: 10px;
						flex: 0 0 100%;
						max-width: 100%;

						&:hover {
							color: $cl_orange; } } } } }

		@include media(sm, emp, -1) {
			.img {
				&-ctrl {
					opacity: 1;
					color: $cl_orange;
					background-color: rgba(#fff, .65);
					padding: 3px 5px;

					.item:not(:last-child) {
						margin-right: 10px; } } } } }

	&-list {
		&>.item {
			margin-bottom: 10px;

			@include media(sm, emp, 0) {
				margin-bottom: 30px; } } }

	&-summary {
		.tab-content {
			padding: 20px 0; }

		.comment-block {
			&+.comment-block {
				margin-top: 20px; }

			.name {
				color: #333; }

			.content {
				color: #656565; }

			.rating {
				font-size: 20px; } } } }

// FILTER
.filter {
	display: flex;
	min-width: 180px;
	margin-bottom: 1rem;
	margin-left: auto;

	&-wrap {
		display: flex;
		align-items: baseline;
		justify-content: space-between;

		.title {
			font-size: 18px;
			color: #515151;

			.number {
				font-size: 14px;
				color: #959595; } } }

	.selectbox {
		width: 100%;

		select {
			padding-left: 30px;
			padding-right: 40px; } }

	@include media(sm, emp, -1) {
		&-wrap {
			flex-wrap: wrap; } } }

// TAB PANEL
.tab {
	&-nav {
		border-bottom: 1px solid #858585;

		.list {
			display: flex; }

		.link {
			padding: 20px 10px;
			border-bottom: 2px solid transparent;

			&.active {
				border-color: #000; } } }

	&-panel {
		visibility: hidden;
		height: 0;
		overflow: hidden;

		&.faded {
			opacity: 0;
			transition: 500ms; }

		&.active {
			visibility: visible;
			height: auto;
			opacity: 1; } } }

.mapbox {
	@include img_box(16,9);

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		height: 100% !important;
		width: 100% !important; } }
