.btn- {
  &white {}
  &black,
  &grey,
  &white,
  &red {
    padding: 7px 15px 5px;
    transition: all .3s;
    min-width: 118px; }

  &white {
    background-color: #fff;
    color: $cl_red;
    border: 1px solid $cl_red;
    box-shadow: 2px 2px 20px -5px #000;

    &:hover {
      background-color: $cl_black;
      color: #fff;
      border-color: #fff; } }

  &black {
    color: #fff;
    background-color: #000;

    &:hover {
      background-color: rgba(#000, .5);
      color: #fff; } }

  &grey {
    background-color: #e8e8e8;

    &:hover {
      color: #fff;
      background-color: #000; } }

  &red {
    border: 1px solid $cl_red;
    color: #fff;
    background-color: transparent;

    &:hover {
      background-color: $cl_red;
      color: #fff; }

    &.style2 {
      background-color: $cl_red;
      color: #fff;
      padding-bottom: 8px;
      min-width: 150px;

      &:hover {
          color: $cl_red;
          background-color: transparent; } } }

  &def {
    padding: 10px 40px;
    background-color: #fff;
    border: 1px solid #a5a5a5;
    color: #515151;
    border-radius: 50px;

    &:hover {
      box-shadow: 0 0 10px 0 #a5a5a5 inset; } }

  &gray-border {
    background-color: #dedede;
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
    height: 100%;
    border-radius: 25px;
    color: #808080;
    transition: all .3s;

    &:hover {
      background-color: $cl_red;
      color: #fff; } }

  &main {
    background-color: $cl_main;
    padding: 10px 30px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;

    &:hover {
      color: #fff;
      background-color: rgba($cl_main, .9); } } }
