// RESET CSS
button {
	cursor: pointer;
	transition: 250ms;

	&:focus {
		outline: none; } }

a {
	transition: all 250ms;
	cursor: pointer;
	color: inherit;

	&:hover,
	&:focus {
		text-decoration: none;
		color: inherit; } }

* {
	outline: none; }

img {
	max-width: 100%;
	max-height: 100%; }

textarea {
	resize: none; }

.reset {
	&-list {
		padding-left: 0;
		margin-bottom: 0;
		list-style: none; }

	&-btn {
		background-color: transparent;
		border: none;
		padding: 0;
		cursor: pointer; } }

.form-control:focus {
	box-shadow: none {
  border-color: unset; } }
