/*!
 * Title: Main style
 * Description: The main style
 * Author: Công ty TNHH TM-DV Thế Giới Web - thegioiwebs.net
 * Version: 1.0
 */
/**
 * The first types of settings
 */
@import "mixin";
@import "variable";
@import "normalize";

/**
 * Additional types of settings
 */
// @import _animation
@import "border";
@import "breadcrumb";
@import "btn";
@import "dropdown";
@import "elements";
@import "font";
@import "form-control";
@import "general";
@import "gutter";
@import "img";
@import "link";
@import "no-category";
@import "_pagination";
@import "slick";

/**
 * Main types of settings
 */
@import "kid";
@import "vvr";
