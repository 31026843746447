body {
  font-family: $fr;
  font-size: 14px;
  color: #000;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  body:before,
  .header:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.6);
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    transition: all .3s; } }

.container {
  max-width: 100%;

  @include min-xl {
    max-width: 1200px; } }

.container, .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  @include max-sm {
    padding-left: 5px;
    padding-right: 5px; } }

.row {
  @include max-sm {
    margin-left: -5px;
    margin-right: -5px; } }

.social-icons {
  a {
    padding: 5px;

    @include min-md {
      padding-left: 10px;
      padding-right: 10px; }

    &:hover {
      color: $cl_black; } } }

/**
 * main-content
 */
.main-content {
 }  // margin-top: 70px

.section {
  @extend .pt-40;
  @extend .pb-20; }

.title-main {
  font-family: $fb;
  text-align: center;
  color: $cl_main;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 20px;

  @include min-lg {
    margin-bottom: 30px; }

  &:before {
    content: '';
    width: 60px;
    height: 2px;
    background-color: $cl_main;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%); } }

.nav {
  margin-bottom: 20px;
  font-size: 14px;

  @include min-lg {
    margin-bottom: 25px; }

  @include min-xl {
    margin-bottom: 30px; }

  &.style-1 {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    // overflow-x: auto
 }    // overflow-y: hidden

  .nav__item {

    // max-width: 33.333%
    // flex: 0 0 33.333%

    // & ~ .nav__item
    //// .nav__link
    //  // border-left: 1px solid #ebebeb

    .nav__link {
      // margin-left: 5px
      // margin-right: 5px
      margin: 5px;
      display: block;
      text-transform: uppercase;
      padding: 5px 15px;
      position: relative;
      color: #b3b4b8;
      border: 1px solid #b3b4b8;
      border-radius: 20px;


      @include min-xl {
        padding: 7px 20px; }

      &.active,
      &:hover {
        position: relative;
        color: #fff;
        border-color: $cl_main;
        background-color: $cl_main;
 } } } }        // #1e73be

.banner-home {
  .style1 {
    .item {
      position: relative;
      overflow: hidden;

      .embed-responsive {
        @include img_box(1519, 650); }

      .content {
        padding: 10px;
        position: absolute;
        width: 90%;
        background-color: rgba(#fff, .7);
        left: -100%;
        transform: translateX(-50%);
        z-index: 2;
        transition: all 1s;

        @include min-md {
          padding: 20px; }

        @include min-lg {
          bottom: 90px; }

        @include max-lg {
          top: 50%;
          transform: translate(-50%, -50%); }

        @include max-sm {
          .small,
          .title {
            @include nowrap; } } }

      &.slick-active {
        .content {
          left: 50%; } } } } }

.product- {
  &new {
    &__item {
      .embed-responsive {
        flex: 0 0 100px;
        max-width: 100px;
        @include img_box(1, 1);

        img {
          transition: all .3s; }

        @include min(1024px) {
          &:hover {
            img {
              transform: translate(-50%, -50%) scale(1.2); } } }

        @include min-xl {
          flex: 0 0 150px;
          max-width: 150px; } }

      .content {
        flex: 0 0 calc(100% - 100px);
        max-width: calc(100% - 100px);
        padding-left: 10px;

        .tag {
          color: #999;
          font-family: $fl;
          margin-bottom: 5px; }

        .title {
          text-transform: uppercase;
          line-height: 1.4; }

        .tag,
        .title {
          @include min(1024px) {
            &:hover {
              color: $cl_main; } } }

        @include min-md {
          padding-left: 25px; }

        @include min-xl {
          flex: 0 0 calc(100% - 150px);
          max-width: calc(100% - 150px); } } } }

  &highlight {
    &__item {
      .embed-responsive {
        @include img_box(1, 1);
        border-radius: 25px;
        overflow: hidden;
        margin-bottom: 10px; }

      .content {
        .title {
          text-transform: uppercase;
          text-align: center;
          color: #999;
          margin-bottom: 5px;

          @include min(1024px) {
            padding-left: 5px;
            padding-right: 5px; }

          a {
            font-size: 12px;

            @include min-sm {
              font-size: 14px; }

            @include min-xl {
              font-size: 16px;
              @include nowrap; } } }

        .price {
          text-align: center;
          font-size: 12px;

          @include min-sm {
            font-size: 14px; }

          @include min-xl {
            font-size: 16px; } } }

      @include min(1024px) {
        // background-color: #fff
        padding: 5px 5px 25px;
        position: relative;

        &::after {
          border-radius: 25px;
          content: '';
          box-shadow: 0 0 15px -2px rgba(#000, .4);
          width: 100%;
          height: 100%;
          background-color: #fff;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1; }

        &::before,
        &::after {
          opacity: 0;
          transition: all .3s; }

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 70px;
          height: 2px;
          background-color: $cl_main;
          z-index: 2; }

        &:hover {
          &::before,
          &::after {
            opacity: 1; } } } } } }

.news {
  padding-bottom: 70px;

  &__item {
    padding: 0 0 10px;

    @include min(360px) {
      padding-left: 5px;
      padding-right: 5px; }

    @include min-lg {
      padding-left: 15px;
      padding-right: 15px; }

    >.link {
      z-index: 4; }

    .title {
      font-size: 15px;
      line-height: 23px;
      max-height: 46px;
      overflow: hidden; }

    .icon {
      @include align(cen);
      z-index: 3;
      color: #fff;
      opacity: 0;

      &:after {
        content: '';
        @include align(cen);
        width: 40px;
        height: 40px;
        border: 2px solid #fff;
        border-radius: 50%; } }

    .embed-responsive {
      @include img_box(3, 2);

      .link {
        background-color: rgba(#000, .2); } }

    @include min(1024px) {
      &:hover {
        .embed-responsive {
          .link {
            opacity: 1; } }

        .icon {
          opacity: 1; }

        .title {
          color: $cl_main; } }

      .content {
        position: relative;
        width: calc(100% - 20px);
        left: 10px;
        margin-top: -40px;
        background-color: #fff;
        padding: 30px 15px 20px;
        z-index: 4;
        box-shadow: 0 2px 10px -2px #b3b3b3;

        &:before {
          content: "";
          width: 1px;
          height: 40px;
          position: absolute;
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 3;
          background-color: #000; } } } } }

.btn-black {
  display: inline-block;
  // font-weight: 600
  // font-family: "Poppins", sans-serif
  text-transform: uppercase;
  color: #FFF;
  background: $cl_black;
  border-radius: 0px;
  line-height: 30px;
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  z-index: 2;
  font-size: 14px;
  letter-spacing: .28px;
  text-align: center;
  // -webkit-transition: all 400ms linear 0s
  // -o-transition: all 400ms linear 0s
  transition: all 400ms linear 0s;
  // -webkit-transform: perspective(1px) translateZ(0)
  transform: perspective(1px) translateZ(0);
  border: 0;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: $cl_main;
    left: 0px;
    top: 0px;
    display: block;
    // -webkit-transform-origin: 0 50%
    // -ms-transform-origin: 0 50%
    transform-origin: 0 50%;
    // -webkit-transition-property: -webkit-transform
    transition-property: -webkit-transform;
    // -o-transition-property: transform
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    // -webkit-transform: scalex(0)
    // -ms-transform: scalex(0)
    transform: scalex(0);
    // -webkit-transition: all 400ms linear 0s
    // -o-transition: all 400ms linear 0s
    transition: all 400ms linear 0s; }

  &:hover {
    &::before {
      // -webkit-transform: scaleY(1)
      // -ms-transform: scaleY(1)
      transform: scaleY(1);
      // -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66)
      // -o-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66)
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); } } }


.contact-us {
  .form-control {
    border: 1px solid #dedbdb;
    // border-radius: 0px
    background: #f2f2f2;
    height: 45px;
    // -webkit-box-shadow: none
    // box-shadow: none
    padding: 5px 15px;
    // outline: none
    // font-size: 17px
    color: #797979;
    // letter-spacing: .51px
 }    // margin-bottom: 0

  textarea.form-control {
    height: 100px;

    @include min-xl {
      height: 150px; } }

  .c_details_item {
    background: transparent;
    border: 1px solid #e5e5e5;
    padding: 15px;
    margin-bottom: 15px;

    @include min-lg {
      padding: 28px 30px; }

    .media {
      display: table;

      .media-left,
      .media-body {
        vertical-align: middle;
        display: table-cell; }

      .media-left {
        padding-right: 15px;

        @include min-lg {
          padding-right: 30px; }

        i {
          height: 40px;
          width: 40px;
          line-height: 38px;
          font-size: 18px;
          border: 1px solid $cl_main;
          background-color: $cl_main;
          border-radius: 50%;
          color: #FFF;
          text-align: center;
          // -webkit-transition: all 400ms linear 0s
          // -o-transition: all 400ms linear 0s
          transition: all 400ms linear 0s;

          @include min-lg {
            height: 50px;
            width: 50px;
            line-height: 48px;
            font-size: 22px; } } } } } }

.recruitment {
  .hasLink>.link {
    z-index: 2; }

  .post-block {
    position: relative;
    z-index: 1;
    display: flex;
    // flex-direction: row
    // flex-wrap: nowrap
    align-items: center;
    min-height: 200px;
    // overflow: hidden
    background: #000;

    @include min-lg {
      min-height: 250px; }

    .post-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      opacity: .4;
      will-change: transform;
      transition: .66s all ease;
      overflow: hidden;

      img {
        @extend .img_full;
        @extend .img_fit;
        @extend .img_cen;
        transition: all .3s; } }

    .post-body {
      position: relative;
      z-index: 1;
      width: 100%;
      padding: 30px 22px 30px 25px;
      color: #fff; }

    @include min(1024px) {
      &:hover {
        .post-image {
          img {
            transform: translate(-50%, -50%) scale(1.05); } }

        .post-header {
          color: $cl_main; } } } }

  &-detail {
    .card {
      border: 0;

      .table-custom {
        td, th {
          border-top: 0;
          border-bottom: 1px solid $cl_blue-light;

          @include max-sm {
            display: block;
            padding-left: 0;
            padding-right: 0; } } }

      .btn-link {
        border-radius: 0;
        border-top: 1px solid rgba(0,0,0,.125);
        border-bottom: 1px solid rgba(0,0,0,.125);
        position: relative;
        padding: 15px 30px 15px 15px;
        text-align: left;
        white-space: unset;
        color: #000;

        &::before {
          content: "̶";
          color: $cl_main;
          font-family: $fl;
          font-size: 28px;
          width: 8px;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%); }

        &, &:hover {
          text-decoration: none; }

        &.collapsed {
          border-bottom: 0;

          &::before {
            content: "+";
            width: unset; } } }

      &:last-child {
        .btn-link {
          border-bottom: 1px solid rgba(0,0,0,.125); } }

      .card-body {
        font-family: $fl; } } } }

.news-page {
  .content-left {
    @include max-sm {
      height: 200px; } }

  .wrapper-tag-list {
    color: #999;
    font-size: 14px;
    padding: 5px 0;
    border-top: 1px dashed #eee;
    border-bottom: 1px dashed #eee;
    margin-top: 10px;
    margin-bottom: 15px; }

  .btn-readmore {
    text-transform: uppercase;
    padding: 7px 20px 6px;
    text-align: center;
    background: #f6f6f6;
    color: #3c3b48;
    border: 1px solid #ddd;
    margin-right: -2px;

    &:hover {
      background-color: $cl_main;
      color: #fff; } }

  .social_icon {
    .item {
      transition: background .3s;

      .link {
        border: 1px solid #ebebeb;
        text-align: center;
        width: 28px;
        height: 28px;
        display: block;
        color: #999;
        line-height: 26px; }

      & ~ .item {
        margin-left: 5px; }

      &:hover {
        .link {
          color: #fff; } }

      &.facebook:hover {
        background-color: #3b5998; }

      &.twitter:hover {
        background-color: #00aced; }

      &.google:hover {
        background-color: #dd4b39; }

      &.pinterest:hover {
        background-color: #cb2027; } } }

  .item_title:hover {
    color: $cl_main; } }

.news-detail-page {
  .entry-date-text {
    color: #aaa; }

  .item_title:hover {
    color: $cl_main; } }

.aside-menu {
  &.style1 {
    .title {
      border-bottom: 2px solid $cl_main;
      padding-bottom: 10px;
      display: inline-block; }

    .lists {
      background-color: #f4f4f4;

      .d-block {
        padding: 10px;

        @include min-lg {
          padding: 15px; }

        &:hover {
          color: $cl_main; } }

      .item ~ .item {
        border-top: 1px solid #fff; } }

    .item {
      a.active {
        color: $cl_main; } } } }

.btn-title {
  position: relative;
  font-size: 1rem;
  text-transform: uppercase;
  color: #fff;
  background: $cl_main;
  line-height: 50px;
  height: 50px;
  padding: 0 15px;
  letter-spacing: 1px;
  margin-bottom: 10px;

  &.toClose {
    @include max-lg {
      padding-left: 45px;

      span {
        font-size: 35px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
        font-family: gotham-light; } } }

  &.style2 {
    text-align: left;
    padding-left: 50px;

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 25px; }

    &::before {
        height: 15px;
        border: {
            style: solid;
            color: #fff transparent;
            width: 1.5px 0; } }

    &::after {
        height: 2px;
        background-color: #fff;
        z-index: 3; } } }

.product-sidebar {
  @include max-lg {
    position: fixed;
    right: -320px;
    width: 320px;
    z-index: 99;
    top: 0;
    background: #fff;
    // padding: 15px
    height: 100vh;
    transition: right .3s;

    .btn-title {
      margin: 15px;
      width: calc(100% - 30px); }

    .filter-category {
      overflow: auto;
      height: calc(100vh - 81px);
      position: absolute;
      width: calc(100% - 30px);
      margin-top: 81px;
      left: 15px;
      top: 0;
      padding-bottom: 15px; }

    &.opened {
      right: 0; } }

  .filter-category > ul {
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;

    > li {
      position: relative;

      > a {
        display: inline-block;
        padding: 10px 15px 10px 10px;
        color: #333;
        // font-size: 0.9375rem
        font-weight: 500;
        display: block;
        border-top: 1px solid #e6e6e6;
        background: whitesmoke; } } } }

.product-sidebar .filter-category > ul > li .btn-showsubfilter {
  position: absolute;
  top: 8px;
  right: 0;
  width: 30px;
  text-align: center;
  cursor: pointer;
  transition: all, 0.3s;
  font-size: 18px;
  color: #666666; }

.product-sidebar .filter-category > ul > li.active > a {
  border-bottom: none;
  color: $cl_main; }

.product-sidebar .filter-category > ul > li .sub li a {
  display: block;
  padding: 5px 15px 5px 40px;
  position: relative;
  color: #666666;
 }  // font-size: 0.875rem

.product-sidebar {
  .sub {
    a {
      font-family: $fl; } }

  .filter-category {
    > ul {
      > li {
        .btn-showsubfilter.active {
          transform: rotateX(180deg); }

        .sub li a:before {
          content: '';
          display: block;
          position: absolute;
          top: 10px;
          left: 20px;
          width: 10px;
          height: 10px;
          border: 1px solid #999999;
          border-radius: 50%; } } } } }

.product-sidebar .filter-category > ul > li .sub li .btn-showsubfilter2.active {
  transform: rotateX(180deg); }

.product-sidebar .filter-category > ul > li .sub li .sub2 ul li a:before {
  content: '\f101';
  font-family: "FontAwesome";
  border: none;
  border-radius: 0;
  position: absolute;
  top: 5px;
  left: 20px; }

.product-sidebar .filter-category > ul > li .sub li .sub2 ul {
    padding-left: 20px; }

.product-sidebar .filter-category > ul > li .sub li {
  font-weight: normal;
  text-transform: none;
  padding: 0px 0px;
  font-size: 0.875rem;
  position: relative; }

.product-sidebar .filter-category > ul > li .sub li .btn-showsubfilter2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  text-align: center;
  cursor: pointer;
  transition: all, 0.3s;
  color: #666;
  font-size: 18px; }

// .product-sidebar .filter-category > ul > li:last-child .sub,
.product-sidebar .filter-category > ul {
  border-bottom: 1px solid #e6e6e6; }

// .product-sidebar .filter-category > ul > li .sub li.active >
.product-sidebar .filter-category > ul > li .sub li.active > a {
  font-weight: 600;
  color: $cl_main; }

.product-sidebar .filter-category > ul > li .sub li.active > a:before {
  background: $cl_main;
  border-color: $cl_main; }

.product-sidebar .filter-category > ul > li .sub li .sub2 ul li.active a:before {
  background: none;
  border-color: transparent;
  color: $cl_main; }

.product-sidebar .filter-category > ul > li .btn-showsubfilter.active {
  transform: rotateX(180deg); }

.product-sidebar .filter-category > ul > li .sub {
  display: none;
  padding: 5px 0 10px;

  &.active {
    display: block; } }

.product-sidebar .filter-category > ul > li .sub li .sub2 {
  display: none;

  &.active {
    display: block; } }

// .product-sidebar .filter-category > ul > li.active .sub
//   display: block

@include min(1024px) {
  .product-sidebar .filter-category > ul > li .sub li .btn-showsubfilter2:hover,
  .product-sidebar .filter-category > ul > li .btn-showsubfilter:hover,
  .product-sidebar .filter-category > ul > li > a:hover,
  .product-sidebar .filter-category > ul > li .sub li:hover > a {
    color: $cl_main; } }

.product-detail-page {
  .description-detail {
    @include max-md {
      max-height: 480px;
      overflow: auto;
      border-bottom: 1px solid #dee2e6; } } }

.product-info .product-sub {
  color: #666666;
 }  // font-size: 0.8125rem

.product-info .product-code {
  margin-top: 10px;
  font-size: 0.875rem;
  color: #666666; }

.product-info hr {
  border-color: #333;
  width: 80px;
  margin: 13px 0 7px; }

.product-info .product-price {
  font-size: 1.375rem;
  color: #4d4d4d;
  text-transform: uppercase;
  margin-bottom: 20px; }

.product-info .share {
  margin-bottom: 20px; }

.product-info .share .text {
  color: #666666;
  display: inline-block;
  margin-right: 20px; }

.product-info .share a {
  // font-size: 1.125rem
  // color: #333
  display: inline-block;
  padding: 5px 10px; }

.product-info .share a.facebook {
  color: #3b5998; }

.product-info .share a.google {
  color: #d11718; }

.product-info .share a.twitter {
  color: #00c5f9; }

.product-info {
  // .share a + a
  //// margin-left: 20px

  .btn-black {
    background: #3055d2;
    border-radius: 5px;
    overflow: hidden;
    text-transform: unset;
    padding-top: 4px; } }

.slider-for,
.slider-nav {
  .item {
    @include img_box(1,1);

    img {
      overflow: hidden;
      border-radius: 20px;
      @extend .img_full;
      @extend .img_fit; } } }

.slider-nav {
  margin: 10px 50px;

  .item {
    cursor: pointer;

    img {
      opacity: .4;
      padding: 5px; }

    &.slick-current {
      img {
        opacity: 1; } } }

  &.nav-style-1 {
    .slick-arrow {
      width: 30px;
      height: 30px;
      background-color: $cl_black;
      border-radius: 50%;

      &:hover {
        background-color: $cl_main; }

      &:before {
        width: 10px;
        height: 10px; } }

    .slick-prev {
      left: -40px;

      &:before {
        left: 55%; } }

    .slick-next {
      right: -40px;

      &:before {
        left: 45%; } } } }


