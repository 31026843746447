.breadcrumb {
	margin-bottom: 0;
	background-color: #fff;
	padding: 15px 0;

	// +media(fhd, emp, 0)
	//  padding: 15*$unit 0

	// &_content
	//  display: flex
	//  flex-wrap: wrap
	//  align-items: center

	//  li
	//    position: relative
	//    text-transform: uppercase
	//    // font-family: $fl

	//    &:not(:last-child)
	//      &:after
	//        content: '>'
	//        padding: 0 5px

	//        // +media(fhd, emp, 0)
	//        //  padding: 0 5*$unit

	& {
		padding-top: 15*$rem;
		padding-bottom: 10*$rem;
		background-color: #f7f7f7;
		border-bottom: 1px solid #dedede;
		// margin-bottom: 30*$rem

		&-list {
			display: flex;
			flex-wrap: wrap;
			font-size: 14*$rem;
			// padding-top: 10*$rem
			// padding-bottom: 20*$rem

			.item {
				margin-bottom: 5*$rem;
				color: $cl_main;

				&:not(:last-child) {
					&:after {
						content: ">";
						margin-left: 10*$rem;
						margin-right: 10*$rem;
						color: #AEAEAE; } }

				&.active {
					color: #AEAEAE; }


				.link {

					&:hover {
						color: $cl_main; } } } } } }

